<template>
  <div class="ostolasku-uusi grey lighten-2">
    <!-- Top bar -->
    <div class="sticky">
      <v-toolbar
        class="v-toolbar--sticky pl-6"
        color="primary"
        flat
        tabs
        :dark="true"
      >
        <v-btn
          :to="{ name: 'ostolasku-selaa' }"
          icon
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title v-if="$route.meta.title">
          {{ $route.meta.title }}
        </v-toolbar-title>
      </v-toolbar>
    </div>


    <div class="px-6 py-12 main-div">
      <!-- Ostolaskun luomisen jälkeinen valikko -->
      <template v-if="luotuOstolasku.id">
        <v-container class="max-width--1200 px-6 py-4 mx-auto mb-12">
          <v-row
            justify="center"
            class="pa-6"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <StatusIcon
                  icon="invoice"
                  size="large"
                  :hover-icon="('open-in-new')"
                  bg-color="primary"
                  :event-listener="on"
                  @click="$router.push({ name: 'ostolasku-nayta', params: { id: luotuOstolasku.id } })"
                />
              </template>
              <template v-slot:default>
                <span>Avaa ostolasku</span>
              </template>
            </v-tooltip>
          </v-row>
          <v-row
            justify="center"
            class="pa-6"
          >
            <span class="title">{{ laskuTallennettuTeksti }} <router-link :to="{ name: 'ostolasku-nayta', params: { id: luotuOstolasku.id } }">{{ luotuOstolasku.nro }}</router-link>.
            </span>
          </v-row>
          <v-row
            justify="center"
            class="pa-6"
          >
            <v-btn
              large
              :to="{ name: 'ostolasku-selaa' }"
              class="mr-2"
            >
              Palaa ostolaskujen hakuun
            </v-btn>
            <v-btn
              large
              color="success"
              class="ml-2"
              @click="palautaAlkutilaan"
            >
              Luo uusi ostolasku
            </v-btn>
          </v-row>
        </v-container>
      </template>


      <!-- Laskun tiedot -->
      <template v-else>
        <div class="paper px-6 py-12 mb-12">
          <FormOstolasku
            ref="formOstolasku"
            v-model="ostolasku"
            @open-dialog-toimittaja="avaaDialogToimittaja"
            @muokkaus="naytaPoistumisvahvistus = true"
          />
        </div>


        <!-- Muistiinpanot -->
        <div
          v-if="muistiinpanoItems.length"
          class="paper px-12 py-9 mb-12"
        >
          <h2 class="headline mb-6">
            Toimittajaan liittyvät muistiinpanot
          </h2>
          <v-data-table
            dense
            :items="muistiinpanoItems"
            :headers="tableHeadersAsiakasMuistiinpanot"
            :hide-default-footer="muistiinpanoItems.length <= 5"
            :items-per-page="5"
            no-data-text="Ei muistiinpanoja"
          >
            <template v-slot:item="{ item }">
              <TableRow>
                <td>
                  {{ item.lahettaja }}
                </td>
                <td class="text-no-wrap text-right">
                  {{ $date(item.aika, 'D.M.YYYY HH:mm') }}
                </td>
                <td class="text-no-wrap text-right">
                  <span v-if="item.ostolasku">
                    <router-link
                      :to="{ name: 'laskutus-nayta', params: { id: item.ostolasku.id } }"
                    >
                      Ostolasku {{ item.ostolasku.nro }}
                    </router-link>
                  </span>
                </td>
                <td class="text-wrap max-width">
                  {{ item.sisalto }}
                </td>
              </TableRow>
            </template>
          </v-data-table>
        </div>

        <!-- Laskun kuva / liitteet -->
        <div class="paper px-9 py-3 mb-6">
          <v-row>
            <v-col
              md="6"
              class="pa-6"
            >
              <h2 class="headline mb-6 ml-0">
                Laskun kuva
              </h2>
              <v-file-input
                v-model="ostolasku.laskunKuva"
                chips
                counter="1"
                title="title"
                placeholder="Lisää laskun kuva..."
                persistent-placeholder
                :validate-on-blur="(true)"
              />
            </v-col>
            <v-col
              md="6"
              class="pa-6"
            >
              <h2 class="headline mb-6 ml-0">
                Liitteet
              </h2>

              <DataTableLaskuliite
                v-if="ostolasku.laskuliite_set.length"
                :items="ostolasku.laskuliite_set"
                :lasku="ostolasku"
              />
              <v-file-input
                v-model="ostolasku.tiedostot"
                chips
                counter="5"
                multiple
                :error-messages="liiteErrors"
                :rules="[$validationRules.required, $validationRules.smallerThanOrEqualTo(5, 5)]"
                title="title"
                placeholder="Lisää liitteitä..."
                persistent-placeholder
                :validate-on-blur="(true)"
              />
            </v-col>
          </v-row>
        </div>

        <!-- Tallennus -->
        <v-row
          justify="center"
          class="max-width--1200 px-6 py-4 mx-auto mb-12"
        >
          <v-btn
            large
            color="success"
            class="mx-5"
            @click="luoOstolasku"
          >
            Luo ostolasku
          </v-btn>
        </v-row>
      </template>
    </div>


    <!-- Dialog: Uusi toimittaja -->
    <CardDialog
      title="Toimittajan tiedot"
      :dialog-auki="toimittajaDialogAuki"
      title-class="px-10 py-6"
      content-class="px-12 py-4"
      width="1024"
      @close="(toimittajaDialogAuki = false)"
    >
      <FormToimittaja
        :kaare-objekti="ostolasku"
        :kenttien-valinnat="kenttienValinnat"
        :on-auki="toimittajaDialogAuki"
        @close="(toimittajaDialogAuki = false)"
        @toimittajaTallennettu="paivitaToimittaja"
      />
    </CardDialog>

    <!-- <template :v-if="process.env.VUE_APP_DEV_MODE== 1"><pre>{{ lasku }}</pre></template> -->
  </div>
</template>

<script>

import { Toimittaja } from '@/class/Toimittaja'
import { Ostolasku } from '@/class/Lasku'
import {
  FormOstolasku,
  FormToimittaja,
} from '@/components'
import { TableHeadersAsiakasMuistiinpanotLaskunLuonnille } from '@/utils/tableHeaders'
import PoistumisvahvistusMixin from '@/mixins/PoistumisvahvistusMixin'
import { MaksimiUploadTiedostoKoko } from '@/utils/constants'

export default {
  name: 'OstolaskuUusi',
  components: {
    FormOstolasku,
    FormToimittaja,
  },
  mixins: [PoistumisvahvistusMixin],
  data () {
    return {
      toimittajaDialogAuki: false,
      kenttienValinnat: {},
      lahetyspaivaMenu: false,
      ostolasku: new Ostolasku(true),
      liiteErrors: [],
      luotuOstolasku: {},
      tableHeadersAsiakasMuistiinpanot: TableHeadersAsiakasMuistiinpanotLaskunLuonnille,
    }
  },
  computed: {
    laskuTallennettuTeksti () {
      return 'Ostolasku tallennettu laskunumerolla'
    },
    muistiinpanoItems () {
      if (!this.ostolasku.toimittaja.muistiinpano_set) return []

      const muistiinpanot = this.ostolasku.toimittaja.muistiinpano_set.filter(item => {
        return !item.paamiesviesti
      })

      return muistiinpanot.map(item => {
        return {
          lahettaja: item.lahettaja.username,
          aika: item.aika,
          ostolasku: item.ostolasku,
          sisalto: item.data,
        }
      })
    },
    warningStyle () {
      return `color: ${this.$vuetify.theme.warning}`
    },
  },
  watch: {
    'ostolasku.toimittaja.id': {
      async handler (id) {
        await this.haeToimittajanKenttienValinnat(id)
        this.ostolasku.viivastyskorko = this.kenttienValinnat.oletusviivkorko
        this.ostolasku.maksuehto = 14
      }
    },
    'ostolasku.tiedostot': {
      handler (val) {
        if (val) {
          this.liiteErrors = []
        }
      }
    }
  },
  created () {
    this.haeToimittajanKenttienValinnat()
  },
  methods: {
  async luoOstolasku () {
      if (!this.validoiOstolasku()) return

      try {
        await this.lahetaOstolasku()
      } catch (e) {
        this.$oletusVirheenkasittely(e, 'Ostolaskun tallennus epäonnistui')

        return
      }

      try {
        await this.lahetaLaskunKuva()
      } catch (e) {
        this.$oletusVirheenkasittely(e, 'Ostolasku on tallennettu, mutta laskun kuvan lähetys epäonnistui! Yritä kuvan tallennusta uudelleen laskunäkymän kautta.')

        return
      }

      try {
        await this.lahetaLiitteet()
      } catch (e) {
        this.$oletusVirheenkasittely(e, 'Ostolasku on tallennettu, mutta liitteiden lähetys epäonnistui! Yritä liitteiden tallennusta uudelleen laskunäkymän kautta.')

        return
      }

      this.$naytaOnnistumisilmoitus('Lasku tallennettu!')
    },
    async lahetaOstolasku () {
      const request = await this.$doRequestWithTimeout(
        this.$api.Ostolaskut,
        {
          method: 'POST',
          url: '',
          body: this.ostolasku.getPostData(),
        },
        'doSingleRequest',
      )

      if (!request.success) throw new this.$HttpError(request)

      this.luotuOstolasku = request.result.body
      this.naytaPoistumisvahvistus = false
    },
    async lahetaLaskunKuva () {
      const { laskunKuva } = this.ostolasku
      if (!laskunKuva || !laskunKuva.name) return

      const formData = new FormData()
      formData.append(laskunKuva.name, laskunKuva)

      const request = await this.$doRequestWithTimeout(
        this.$api.Ostolasku,
        {
          method: 'POST',
          url: 'toiminnot/tuo_laskun_kuva/',
          body: formData,
          params: {
            id: this.luotuOstolasku.id
          }
        },
        'doSingleRequest',
      )

      if (!request.success) throw new this.$HttpError(request)
    },
    async lahetaLiitteet () {
  const formData = new FormData()

      for (var tiedosto of this.ostolasku.tiedostot) {
        formData.append(tiedosto.name, tiedosto)
      }

      const request = await this.$doRequestWithTimeout(
        this.$api.Ostolasku,
        {
          method: 'POST',
          url: 'toiminnot/tuo_liite/',
          body: formData,
          params: {
            id: this.luotuOstolasku.id
          }
        },
        'doSingleRequest',
      )

      if (!request.success) throw new this.$HttpError(request)
    },
    validoiOstolasku () {
      let lomakeValidi = true

      if (this.ostolasku.tiedostot.length > 5) {
        this.liiteErrors = [this.$t('common:attachmentTooMany')]
        lomakeValidi = false
      }

      let liianSuurenTiedostonNimi = ''
      const sallittuTiedostoKokoYlitetty = this.ostolasku.tiedostot.some(tiedosto => {
        liianSuurenTiedostonNimi = tiedosto.name
        return tiedosto.size > MaksimiUploadTiedostoKoko
      })
      if (sallittuTiedostoKokoYlitetty) {
        this.liiteErrors = [this.$t('common:attachmentSizeExceeded', { filename: liianSuurenTiedostonNimi })]
        lomakeValidi = false
      }

      if (!this.$refs.formOstolasku.validate()) {
        this.$naytaVirheilmoitus('Tarkista kenttien sisältö!')
        lomakeValidi = false
      }

      return lomakeValidi
    },
    avaaDialogToimittaja () {
      this.toimittajaDialogAuki = true
    },
    async haeToimittajanKenttienValinnat (id) {
      this.kenttienValinnat = await Toimittaja.haeToimittajanKenttienValinnat(id)
    },
    palautaAlkutilaan () {
      this.luotuOstolasku = {}
      this.ostolasku = new Ostolasku(true)
    },
    paivitaToimittaja (toimittaja) {
      this.ostolasku.toimittaja = toimittaja
    },
  },
}

</script>

<style lang="scss" scoped>

.main-div {
  min-height: calc(100vh - 64px);
}

</style>
