var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ostolasku-uusi grey lighten-2" },
    [
      _c(
        "div",
        { staticClass: "sticky" },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "v-toolbar--sticky pl-6",
              attrs: { color: "primary", flat: "", tabs: "", dark: true },
            },
            [
              _c(
                "v-btn",
                { attrs: { to: { name: "ostolasku-selaa" }, icon: "" } },
                [_c("v-icon", [_vm._v("arrow_back")])],
                1
              ),
              _vm.$route.meta.title
                ? _c("v-toolbar-title", [
                    _vm._v(" " + _vm._s(_vm.$route.meta.title) + " "),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "px-6 py-12 main-div" },
        [
          _vm.luotuOstolasku.id
            ? [
                _c(
                  "v-container",
                  { staticClass: "max-width--1200 px-6 py-4 mx-auto mb-12" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "pa-6", attrs: { justify: "center" } },
                      [
                        _c("v-tooltip", {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _c("StatusIcon", {
                                      attrs: {
                                        icon: "invoice",
                                        size: "large",
                                        "hover-icon": "open-in-new",
                                        "bg-color": "primary",
                                        "event-listener": on,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "ostolasku-nayta",
                                            params: {
                                              id: _vm.luotuOstolasku.id,
                                            },
                                          })
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("span", [_vm._v("Avaa ostolasku")]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3669138888
                          ),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "pa-6", attrs: { justify: "center" } },
                      [
                        _c(
                          "span",
                          { staticClass: "title" },
                          [
                            _vm._v(_vm._s(_vm.laskuTallennettuTeksti) + " "),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "ostolasku-nayta",
                                    params: { id: _vm.luotuOstolasku.id },
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.luotuOstolasku.nro))]
                            ),
                            _vm._v(". "),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "v-row",
                      { staticClass: "pa-6", attrs: { justify: "center" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              large: "",
                              to: { name: "ostolasku-selaa" },
                            },
                          },
                          [_vm._v(" Palaa ostolaskujen hakuun ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "ml-2",
                            attrs: { large: "", color: "success" },
                            on: { click: _vm.palautaAlkutilaan },
                          },
                          [_vm._v(" Luo uusi ostolasku ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "paper px-6 py-12 mb-12" },
                  [
                    _c("FormOstolasku", {
                      ref: "formOstolasku",
                      on: {
                        "open-dialog-toimittaja": _vm.avaaDialogToimittaja,
                        muokkaus: function ($event) {
                          _vm.naytaPoistumisvahvistus = true
                        },
                      },
                      model: {
                        value: _vm.ostolasku,
                        callback: function ($$v) {
                          _vm.ostolasku = $$v
                        },
                        expression: "ostolasku",
                      },
                    }),
                  ],
                  1
                ),
                _vm.muistiinpanoItems.length
                  ? _c(
                      "div",
                      { staticClass: "paper px-12 py-9 mb-12" },
                      [
                        _c("h2", { staticClass: "headline mb-6" }, [
                          _vm._v(" Toimittajaan liittyvät muistiinpanot "),
                        ]),
                        _c("v-data-table", {
                          attrs: {
                            dense: "",
                            items: _vm.muistiinpanoItems,
                            headers: _vm.tableHeadersAsiakasMuistiinpanot,
                            "hide-default-footer":
                              _vm.muistiinpanoItems.length <= 5,
                            "items-per-page": 5,
                            "no-data-text": "Ei muistiinpanoja",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item",
                                fn: function (ref) {
                                  var item = ref.item
                                  return [
                                    _c("TableRow", [
                                      _c("td", [
                                        _vm._v(
                                          " " + _vm._s(item.lahettaja) + " "
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "text-no-wrap text-right",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$date(
                                                  item.aika,
                                                  "D.M.YYYY HH:mm"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "text-no-wrap text-right",
                                        },
                                        [
                                          item.ostolasku
                                            ? _c(
                                                "span",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: {
                                                          name: "laskutus-nayta",
                                                          params: {
                                                            id: item.ostolasku
                                                              .id,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Ostolasku " +
                                                          _vm._s(
                                                            item.ostolasku.nro
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-wrap max-width" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.sisalto) + " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3926567529
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "paper px-9 py-3 mb-6" },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "pa-6", attrs: { md: "6" } },
                          [
                            _c("h2", { staticClass: "headline mb-6 ml-0" }, [
                              _vm._v(" Laskun kuva "),
                            ]),
                            _c("v-file-input", {
                              attrs: {
                                chips: "",
                                counter: "1",
                                title: "title",
                                placeholder: "Lisää laskun kuva...",
                                "persistent-placeholder": "",
                                "validate-on-blur": true,
                              },
                              model: {
                                value: _vm.ostolasku.laskunKuva,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ostolasku, "laskunKuva", $$v)
                                },
                                expression: "ostolasku.laskunKuva",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "pa-6", attrs: { md: "6" } },
                          [
                            _c("h2", { staticClass: "headline mb-6 ml-0" }, [
                              _vm._v(" Liitteet "),
                            ]),
                            _vm.ostolasku.laskuliite_set.length
                              ? _c("DataTableLaskuliite", {
                                  attrs: {
                                    items: _vm.ostolasku.laskuliite_set,
                                    lasku: _vm.ostolasku,
                                  },
                                })
                              : _vm._e(),
                            _c("v-file-input", {
                              attrs: {
                                chips: "",
                                counter: "5",
                                multiple: "",
                                "error-messages": _vm.liiteErrors,
                                rules: [
                                  _vm.$validationRules.required,
                                  _vm.$validationRules.smallerThanOrEqualTo(
                                    5,
                                    5
                                  ),
                                ],
                                title: "title",
                                placeholder: "Lisää liitteitä...",
                                "persistent-placeholder": "",
                                "validate-on-blur": true,
                              },
                              model: {
                                value: _vm.ostolasku.tiedostot,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ostolasku, "tiedostot", $$v)
                                },
                                expression: "ostolasku.tiedostot",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "max-width--1200 px-6 py-4 mx-auto mb-12",
                    attrs: { justify: "center" },
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mx-5",
                        attrs: { large: "", color: "success" },
                        on: { click: _vm.luoOstolasku },
                      },
                      [_vm._v(" Luo ostolasku ")]
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Toimittajan tiedot",
            "dialog-auki": _vm.toimittajaDialogAuki,
            "title-class": "px-10 py-6",
            "content-class": "px-12 py-4",
            width: "1024",
          },
          on: {
            close: function ($event) {
              _vm.toimittajaDialogAuki = false
            },
          },
        },
        [
          _c("FormToimittaja", {
            attrs: {
              "kaare-objekti": _vm.ostolasku,
              "kenttien-valinnat": _vm.kenttienValinnat,
              "on-auki": _vm.toimittajaDialogAuki,
            },
            on: {
              close: function ($event) {
                _vm.toimittajaDialogAuki = false
              },
              toimittajaTallennettu: _vm.paivitaToimittaja,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }