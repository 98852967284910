import { render, staticRenderFns } from "./OstolaskuSelaa.vue?vue&type=template&id=cbb7cfaa&scoped=true&"
import script from "./OstolaskuSelaa.vue?vue&type=script&lang=js&"
export * from "./OstolaskuSelaa.vue?vue&type=script&lang=js&"
import style0 from "./OstolaskuSelaa.vue?vue&type=style&index=0&id=cbb7cfaa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbb7cfaa",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCheckbox,VDataTable,VNavigationDrawer,VProgressLinear,VSpacer,VToolbar,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cbb7cfaa')) {
      api.createRecord('cbb7cfaa', component.options)
    } else {
      api.reload('cbb7cfaa', component.options)
    }
    module.hot.accept("./OstolaskuSelaa.vue?vue&type=template&id=cbb7cfaa&scoped=true&", function () {
      api.rerender('cbb7cfaa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/OstolaskuSelaa.vue"
export default component.exports