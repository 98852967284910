var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ostolasku-toimittaja-nayta" },
    [
      _c(
        "ViewTopBarBase",
        { attrs: { title: _vm.routeTitle } },
        [
          _c("v-menu", {
            attrs: { bottom: "", left: "", "nudge-bottom": "40" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        { attrs: { color: "success", depressed: "" } },
                        on
                      ),
                      [
                        _vm._v(" Toiminnot "),
                        _c("v-icon", { attrs: { right: "" } }, [
                          _vm._v(" more_vert "),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "default",
                fn: function () {
                  return [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function ($event) {
                                _vm.toimittajaDialogAuki = true
                              },
                            },
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("edit")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(" Muokkaa toimittajaa "),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          { on: { click: _vm.avaaDialogMuistiinpanot } },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("anitta-icon-note")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(" Kirjoita muistiinpano "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm.tietueLataamassa
        ? _c("Spinner")
        : _vm.tietueLatausEpaonnistunut
        ? _c("CardPahoittelu")
        : [
            _c("v-container", { staticClass: "pa-2", attrs: { fluid: "" } }, [
              _c(
                "div",
                { staticClass: "pa-4" },
                [
                  _c("TietueenPerustiedotCard", {
                    attrs: {
                      paamies: _vm.toimittaja.paamies,
                      vastapuoli: _vm.toimittaja,
                      "vastapuoli-rooli": "toimittaja",
                      "ei-vastapuoli-linkkia": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "col2",
                        fn: function () {
                          return [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-0 font-weight-bold grey--text darken-2",
                              },
                              [_vm._v(" Toimittajatiedot ")]
                            ),
                            _c(
                              "DefList",
                              { attrs: { bordered: true } },
                              [
                                _c("DefListItem", {
                                  attrs: {
                                    "label-class": "defList__label--emphasized",
                                    "value-class": "font-weight-bold",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [_vm._v(" Toimittajanumero ")]
                                        },
                                        proxy: true,
                                      },
                                      _vm.toimittaja.nro
                                        ? {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.toimittaja.nro) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("DefListItem", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$sovellusIdOtsake) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "value",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.toimittaja.id) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _vm.toimittaja.tunnus
                                  ? _c("DefListItem", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.tunnustyyppi) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.toimittaja.tunnus
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        2747204235
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "col3",
                        fn: function () {
                          return [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-0 font-weight-bold grey--text darken-2",
                              },
                              [_vm._v(" Yhteystiedot ")]
                            ),
                            _c(
                              "DefList",
                              { attrs: { bordered: true } },
                              [
                                _c("DefListItem", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [_vm._v(" Puhelin ")]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "value",
                                      fn: function () {
                                        return [
                                          _vm.toimittaja.puhelin
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.toimittaja.puhelin
                                                      .numero
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _c("DefListItem", {
                                  attrs: {
                                    "value-class": "text-wrap break-all",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [_vm._v(" Sähköposti ")]
                                        },
                                        proxy: true,
                                      },
                                      _vm.toimittaja.emailosoite &&
                                      _vm.toimittaja.emailosoite.email
                                        ? {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.toimittaja.emailosoite
                                                        .email
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("DefListItem", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [_vm._v(" Verkkolaskuosoite ")]
                                        },
                                        proxy: true,
                                      },
                                      _vm.toimittaja.verkkolaskuosoite &&
                                      _vm.toimittaja.verkkolaskuosoite
                                        .verkkolaskuosoite
                                        ? {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.toimittaja
                                                        .verkkolaskuosoite
                                                        .verkkolaskuosoite
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm.toimittaja.verkkolaskuosoite &&
                                _vm.toimittaja.verkkolaskuosoite.operaattori
                                  ? _c("DefListItem", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " Verkkolaskuoperaattori "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.toimittaja
                                                        .verkkolaskuosoite
                                                        .operaattori
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        2575012542
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "col4",
                        fn: function () {
                          return [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-0 font-weight-bold grey--text darken-2",
                              },
                              [_vm._v(" Lisätiedot ")]
                            ),
                            _c(
                              "DefList",
                              { attrs: { bordered: true } },
                              [
                                _c("DefListItem", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [_vm._v(" Tyyppi ")]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "value",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.muotoiltuTyyppi) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _c("DefListItem", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [_vm._v(" Kieli ")]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "value",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.toimittaja.kieli) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _vm.toimittaja.kotipaikka
                                  ? _c("DefListItem", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [_vm._v(" Kotipaikka ")]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.toimittaja.kotipaikka
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        1221385979
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pa-4" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12", lg: "8" } }, [
                        _c(
                          "div",
                          { staticClass: "pb-4" },
                          [
                            _c(
                              "v-card",
                              { staticClass: "pa-6" },
                              [
                                _c("CardTitle", {
                                  attrs: { text: "Ostolaskut" },
                                }),
                                _c("v-data-table", {
                                  attrs: {
                                    items: _vm.ostolaskuItems,
                                    headers: _vm.tableHeadersToimittajaLaskut,
                                    "hide-default-footer":
                                      _vm.ostolaskuItems.length <= 5,
                                    "items-per-page": 5,
                                    "no-data-text": "Ei laskuja",
                                    dense: "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "item",
                                      fn: function (ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "TableRow",
                                            {
                                              attrs: {
                                                "custom-contextmenu": "",
                                              },
                                              on: {
                                                contextmenu: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.avaaMenuContext(
                                                    $event,
                                                    item,
                                                    "ostolasku-nayta"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "td",
                                                { staticClass: "text-no-wrap" },
                                                [
                                                  _c("v-tooltip", {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            return [
                                                              item.ikoni_ja_vari
                                                                ? _c(
                                                                    "StatusIcon",
                                                                    {
                                                                      attrs: {
                                                                        icon: item
                                                                          .ikoni_ja_vari
                                                                          .ikoni,
                                                                        size: "small",
                                                                        "hover-icon":
                                                                          "open-in-new",
                                                                        "bg-color":
                                                                          item
                                                                            .ikoni_ja_vari
                                                                            .vari,
                                                                        "event-listener":
                                                                          on,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            _vm.$router.push(
                                                                              {
                                                                                name: "ostolasku-nayta",
                                                                                params:
                                                                                  {
                                                                                    id: String(
                                                                                      item.id
                                                                                    ),
                                                                                  },
                                                                              }
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "default",
                                                          fn: function () {
                                                            return [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Avaa laskun tiedot"
                                                                ),
                                                              ]),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-no-wrap" },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: {
                                                          name: "ostolasku-nayta",
                                                          params: {
                                                            id: item.id,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.nro) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "max-width" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.tila) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-no-wrap text-right",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.laskupaiva) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-no-wrap text-right",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.erapaiva) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-no-wrap text-right",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "formatSumToFixed2"
                                                        )(item.summa)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-no-wrap text-right",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "formatSumToFixed2"
                                                        )(item.veroton)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-no-wrap text-right",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "formatSumToFixed2"
                                                        )(item.avoinna)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "py-4" },
                          [
                            _c(
                              "v-card",
                              { staticClass: "pa-6" },
                              [
                                _c("CardTitle", {
                                  attrs: { text: "Tapahtumat" },
                                }),
                                _c("v-data-table", {
                                  attrs: {
                                    items: _vm.tapahtumatItems,
                                    headers:
                                      _vm.tableHeadersToimittajaTapahtumat,
                                    "hide-default-footer":
                                      _vm.tapahtumatItems.length <= 5,
                                    "items-per-page": 5,
                                    "no-data-text": "Ei tapahtumia",
                                    dense: "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "item",
                                      fn: function (ref) {
                                        var item = ref.item
                                        return [
                                          _c("TableRow", [
                                            _c(
                                              "td",
                                              { staticClass: "max-width" },
                                              [
                                                _c("strong", [
                                                  _vm._v(_vm._s(item.maksaja)),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "text-no-wrap text-right",
                                              },
                                              [
                                                _vm._l(
                                                  item.laskut,
                                                  function (lasku) {
                                                    return [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          key:
                                                            lasku.id + item.id,
                                                          attrs: {
                                                            to: {
                                                              name: "ostolasku-nayta",
                                                              params: {
                                                                id: lasku.id,
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              on: {
                                                                contextmenu:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.avaaMenuContext(
                                                                      $event,
                                                                      item,
                                                                      "ostolasku-nayta"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Lasku " +
                                                                  _vm._s(
                                                                    lasku.nro
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c("v-spacer", {
                                                        key: lasku.id,
                                                      }),
                                                    ]
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "text-no-wrap text-right",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$date(
                                                        item.kirjauspaiva
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "text-no-wrap text-right",
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.viite) + " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "max-width" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.viesti) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "text-no-wrap text-right",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f(
                                                        "formatSumToFixed2"
                                                      )(item.summa)
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.muistiinpanoItems.length
                          ? _c(
                              "div",
                              { staticClass: "py-4" },
                              [
                                _c(
                                  "v-card",
                                  { staticClass: "pa-6" },
                                  [
                                    _c("CardTitle", {
                                      attrs: { text: "Muistiinpanot" },
                                    }),
                                    _c("v-data-table", {
                                      attrs: {
                                        items: _vm.muistiinpanoItems,
                                        headers:
                                          _vm.tableHeadersToimittajaMuistiinpanot,
                                        "hide-default-footer":
                                          _vm.muistiinpanoItems.length <= 5,
                                        "items-per-page": 5,
                                        "no-data-text": "Ei muistiinpanoja",
                                        dense: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                _c("TableRow", [
                                                  _c("td", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.lahettaja) +
                                                        " "
                                                    ),
                                                  ]),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap text-right",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$date(
                                                              item.aika,
                                                              "D.M.YYYY HH:mm"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap text-right",
                                                    },
                                                    [
                                                      item.lasku
                                                        ? _c(
                                                            "span",
                                                            {
                                                              on: {
                                                                contextmenu:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.avaaMenuContext(
                                                                      $event,
                                                                      {
                                                                        id: item
                                                                          .lasku
                                                                          .id,
                                                                      },
                                                                      "ostolasku-nayta"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "router-link",
                                                                {
                                                                  attrs: {
                                                                    to: {
                                                                      name: "ostolasku-nayta",
                                                                      params: {
                                                                        id: item
                                                                          .lasku
                                                                          .id,
                                                                      },
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Ostolasku " +
                                                                      _vm._s(
                                                                        item
                                                                          .lasku
                                                                          .nro
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-wrap max-width",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.sisalto) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("td", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-row",
                                                      },
                                                      [
                                                        _c("v-tooltip", {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "div",
                                                                      _vm._g(
                                                                        {},
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                text: "",
                                                                                icon: "",
                                                                                small:
                                                                                  "",
                                                                                disabled:
                                                                                  !item.muokattavissa,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.avaaDialogMuistiinpanoMuokkaa(
                                                                                    item
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    small:
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " edit "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                              item.muokattavissa
                                                                ? {
                                                                    key: "default",
                                                                    fn: function () {
                                                                      return [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "Muokkaa muistiinpanoa"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    },
                                                                    proxy: true,
                                                                  }
                                                                : {
                                                                    key: "default",
                                                                    fn: function () {
                                                                      return [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "Tiettyyn ostolaskuun liittyvää muistiinpanoa ei voi muokata toimittajanäkymässä"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    },
                                                                    proxy: true,
                                                                  },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                        _c("v-tooltip", {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "div",
                                                                      _vm._g(
                                                                        {},
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                text: "",
                                                                                icon: "",
                                                                                small:
                                                                                  "",
                                                                                disabled:
                                                                                  !item.muokattavissa,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.poistaMuistiinpano(
                                                                                    item.id
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    small:
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " delete "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                              item.muokattavissa
                                                                ? {
                                                                    key: "default",
                                                                    fn: function () {
                                                                      return [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "Poista muistiinpano"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    },
                                                                    proxy: true,
                                                                  }
                                                                : {
                                                                    key: "default",
                                                                    fn: function () {
                                                                      return [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "Tiettyyn ostolaskuun liittyvää muistiinpanoa ei voi poistaa toimittajanäkymässä"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    },
                                                                    proxy: true,
                                                                  },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        590155273
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.viestiItems.length
                          ? _c(
                              "div",
                              { staticClass: "py-4" },
                              [
                                _c(
                                  "v-card",
                                  { staticClass: "pa-6" },
                                  [
                                    _c("CardTitle", {
                                      attrs: { text: "Viestit" },
                                    }),
                                    _c("v-data-table", {
                                      attrs: {
                                        items: _vm.viestiItems,
                                        headers:
                                          _vm.tableHeadersToimittajaViestit,
                                        "hide-default-footer":
                                          _vm.viestiItems.length <= 5,
                                        "items-per-page": 5,
                                        "no-data-text": "Ei viestejä",
                                        dense: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                _c("TableRow", [
                                                  _c("td", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.lahettaja) +
                                                        " "
                                                    ),
                                                  ]),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap text-right",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$date(
                                                              item.aika,
                                                              "D.M.YYYY HH:mm"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap text-right",
                                                    },
                                                    [
                                                      item.lasku
                                                        ? _c(
                                                            "span",
                                                            {
                                                              on: {
                                                                contextmenu:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.avaaMenuContext(
                                                                      $event,
                                                                      {
                                                                        id: item
                                                                          .lasku
                                                                          .id,
                                                                      },
                                                                      "ostolasku-nayta"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "router-link",
                                                                {
                                                                  attrs: {
                                                                    to: {
                                                                      name: "ostolasku-nayta",
                                                                      params: {
                                                                        id: item
                                                                          .lasku
                                                                          .id,
                                                                      },
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Lasku " +
                                                                      _vm._s(
                                                                        item
                                                                          .lasku
                                                                          .nro
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-wrap max-width",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          class:
                                                            item.luettu === "–"
                                                              ? "font-weight-bold"
                                                              : "",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.sisalto)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.luettu) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2411312201
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "4" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "pa-6" },
                            [
                              _c("CardTitle", {
                                attrs: { text: "Muut tiedot" },
                              }),
                              _c("ToimittajaLaskutuksenYhteenveto", {
                                attrs: {
                                  "title-class": "mt-4",
                                  toimittaja: _vm.toimittaja,
                                },
                              }),
                              _vm.toimittajarekisteriItems.length
                                ? [
                                    _c("h3", { staticClass: "my-2" }, [
                                      _vm._v(" Toimittajarekisterit "),
                                    ]),
                                    _c("v-data-table", {
                                      attrs: {
                                        items: _vm.toimittajarekisteriItems,
                                        headers:
                                          _vm.tableHeadersToimittajaToimittajarekisteri,
                                        "hide-default-footer":
                                          _vm.toimittajarekisteriItems.length <=
                                          5,
                                        "items-per-page": 5,
                                        "no-data-text":
                                          "Ei toimittajarekistereitä",
                                        dense: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                _c("TableRow", [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.nro) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "text-wrap",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.nimi) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "text-wrap",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.ryhma) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        4248417748
                                      ),
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
      _c("CardDialog", {
        attrs: {
          title: "Toimittajakohtaiset muistiinpanot",
          "dialog-auki": _vm.muistiinpanotDialogAuki,
          "scroll-to-bottom": true,
          "content-class": "px-4 py-2",
        },
        on: {
          close: function ($event) {
            _vm.muistiinpanotDialogAuki = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _vm.muistiinpanotAikajana.length
                  ? _c("Timeline", {
                      staticClass: "px-4",
                      attrs: {
                        items: _vm.muistiinpanotAikajana,
                        "show-title": false,
                        "show-item-titles": false,
                        "show-dates": true,
                        "enable-zoom": false,
                        "enable-direction-toggle": false,
                        "salli-muistiinpanojen-muokkaus": false,
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("FormUusiViesti", {
                  attrs: {
                    loading: _vm.uusiMuistiinpanoFormLoading,
                    "focus-on-update": true,
                    icon: "note",
                    "msg-input-placeholder": "Uusi muistiinpano",
                    "submit-btn-tooltip-text": "Kirjoita muistiinpano",
                  },
                  on: { submit: _vm.muistiinpanoSubmit },
                  model: {
                    value: _vm.uusiMuistiinpanoFormData,
                    callback: function ($$v) {
                      _vm.uusiMuistiinpanoFormData = $$v
                    },
                    expression: "uusiMuistiinpanoFormData",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Muokkaa toimittajaa",
            "dialog-auki": _vm.toimittajaDialogAuki,
            "title-class": "px-12 py-6",
            "content-class": "px-12 py-4",
            width: "1024",
          },
          on: {
            close: function ($event) {
              _vm.toimittajaDialogAuki = false
            },
          },
        },
        [
          _c("FormToimittaja", {
            attrs: {
              "kaare-objekti": _vm.toimittajaKaare,
              "kenttien-valinnat": _vm.kenttienValinnat,
              "on-auki": _vm.toimittajaDialogAuki,
            },
            on: {
              close: function ($event) {
                _vm.toimittajaDialogAuki = false
              },
              toimittajaTallennettu: _vm.kasittelePaivitys,
            },
          }),
        ],
        1
      ),
      _c("CardDialog", {
        attrs: {
          title: "Muokkaa muistiinpanoa",
          "dialog-auki": _vm.muistiinpanoMuokkaaDialogAuki,
          "scroll-to-bottom": true,
          "content-class": "px-4 py-2",
        },
        on: {
          close: function ($event) {
            _vm.muistiinpanoMuokkaaDialogAuki = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "footer",
            fn: function () {
              return [
                _c("FormUusiViesti", {
                  attrs: {
                    loading: _vm.muistiinpanoMuokkaaFormLoading,
                    "focus-on-update": true,
                    icon: "note",
                    "submit-btn-tooltip-text": "Tallenna",
                  },
                  on: { submit: _vm.muistiinpanoMuokkaaSubmit },
                  model: {
                    value: _vm.muistiinpanoMuokkaaFormData,
                    callback: function ($$v) {
                      _vm.muistiinpanoMuokkaaFormData = $$v
                    },
                    expression: "muistiinpanoMuokkaaFormData",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("MenuContext", {
        attrs: {
          "onko-nakyvissa": _vm.menuContextNakyvissa,
          "position-x": _vm.menuContextX,
          "position-y": _vm.menuContextY,
          vaihtoehdot: _vm.menuContextVaihtoehdot,
        },
        on: {
          avaaValilehteen: _vm.avaaUudessaValilehdessa,
          "update:return-value": _vm.suljeMenuContext,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }