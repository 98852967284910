<template>
  <div class="ostolasku-toimittaja-selaa">
    <!-- Off-canvas -->
    <v-navigation-drawer
      ref="offCanvas"
      v-model="showOffCanvas"
      width="450"
      app
      right
      temporary
    >
      <Spinner v-if="loadingOffCanvas" />
      <OffCanvasToimittaja
        v-else
        v-model="selectedItem.item"
        @close-button-click="setShowOffCanvas"
        @messageSubmit="refresh"
        @msg-mark-as-unread="refresh"
        @msg-mark-as-read="refresh"
      />
    </v-navigation-drawer>

    <!-- Top bar -->
    <ViewSelaaTopBarBase
      pikahaun-placeholder-teksti="Hae (toimittajanumero, ID, tunnus)"
      :vuex-module-name="vuexModuleName"
      @open-search-panel="openSearchPanel"
      @close-search-panel="closeSearchPanel"
    />

    <!-- Advanced search -->
    <TransitionSlidePanel>
      <div
        v-show="searchPanelOpen"
        class="pa-1 primary darken-1 shadow--inset"
      >
        <InfoValittuValilehti
          :current-tab="currentTab"
        />
        <FormSearchVastapuoli
          v-model="searchTerms"
          vastapuoli-rooli="toimittaja"
        />
      </div>
    </TransitionSlidePanel>

    <!-- Toolbar -->
    <ViewSelaaToolbar
      :vuex-module-name="vuexModuleName"
      item-partitiivi="toimittajaa"
      :alt-selaa-btn-config="{ toName: 'ostolasku-selaa', itemPartitiivi: 'ostolaskuja' }"
      :uusi-btn-config="{ click: avaaDialogToimittaja, itemName: 'toimittaja' }"
    />

    <!-- Show items as table -->
    <v-data-table
      class="v-data-table--striped"
      :headers="tableHeaders"
      :items="items"
      :hide-default-footer="items.length < 10"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50, 100],
      }"
      :multi-sort="true"
      :server-items-length="itemsTotal"
      :options.sync="pagination"
      :loading="loading"
      loading-text=""
      no-data-text=""
      :dense="tiivisNakyma"
    >
      <template v-slot:progress>
        <v-progress-linear
          color="primary"
          indeterminate
        />
      </template>
      <template v-slot:item="{ item }">
        <TableRow
          :clickable="(true)"
          :data="item"
          :fade="loading"
          :custom-contextmenu="(true)"
          @contextmenu.prevent="avaaMenuContext($event, item)"
          @click="setSelectedItem({ item })"
        >
          <td class="text-no-wrap">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <StatusIcon
                  v-if="item.ikoni_ja_vari"
                  :icon="item.ikoni_ja_vari.ikoni"
                  :size="tiivisNakyma ? 'small' : 'medium'"
                  :hover-icon="('open-in-new')"
                  :bg-color="item.ikoni_ja_vari.vari"
                  :event-listener="on"
                  @click="$router.push({ name: 'ostolasku-toimittaja-nayta', params: { id: item.id } })"
                />
              </template>
              <template v-slot:default>
                <span>Avaa toimittajan tiedot</span>
              </template>
            </v-tooltip>
          </td>
          <td class="max-width">
            {{ item.nimi }}
          </td>
          <td class="text-no-wrap">
            {{ item.nro }}
          </td>
          <td class="text-no-wrap">
            {{ item.id }}
          </td>
          <td class="text-no-wrap">
            {{ item.tunnus }}
          </td>
          <td class="text-no-wrap">
            {{ item.tyyppi }}
          </td>
          <td class="text-no-wrap">
            {{ item.paamies.nimi }} ({{ item.paamies.id }})
          </td>
        </TableRow>
      </template>
    </v-data-table>

    <!-- Pagination -->
    <v-toolbar
      v-if="(pagesTotal > 1)"
      class="py-6 transparent"
      flat
    >
      <v-spacer />
      <Pagination
        v-model="pagination.page"
        :length="pagesTotal"
        :disabled="loading"
      />
      <v-spacer />
    </v-toolbar>

    <!-- Dialog: Uusi toimittaja -->
    <CardDialog
      title="Uusi toimittaja"
      :dialog-auki="toimittajaDialogAuki"
      title-class="px-12 py-6"
      content-class="px-12 py-4"
      width="1024"
      @close="(toimittajaDialogAuki = false)"
    >
      <FormToimittaja
        :kenttien-valinnat="kenttienValinnat"
        :on-auki="toimittajaDialogAuki"
        @close="(toimittajaDialogAuki = false)"
        @toimittajaTallennettu="siirryToimittajanakymaan"
      />
    </CardDialog>

    <!-- Välilehti-dialog -->
    <MenuContext
      :onko-nakyvissa="menuContextNakyvissa"
      :position-x="menuContextX"
      :position-y="menuContextY"
      :vaihtoehdot="menuContextVaihtoehdot"
      @avaaValilehteen="avaaUudessaValilehdessa"
      @update:return-value="suljeMenuContext"
    />
  </div>
</template>

<script>

import { Toimittaja } from '@/class/Toimittaja'
import {
  FormSearchVastapuoli,
  FormToimittaja,
  InfoValittuValilehti,
  OffCanvasToimittaja,
  Pagination,
  TransitionSlidePanel,
  ViewSelaaToolbar,
  ViewSelaaTopBarBase,
} from '@/components'
import ViewSelaaMixin from '@/mixins/ViewSelaaMixin'
import { TableHeadersToimittaja } from '@/utils/tableHeaders'

export default {
  name: 'OstolaskuToimittajaSelaa',
  components: {
    FormSearchVastapuoli,
    FormToimittaja,
    InfoValittuValilehti,
    OffCanvasToimittaja,
    Pagination,
    TransitionSlidePanel,
    ViewSelaaToolbar,
    ViewSelaaTopBarBase,
  },
  mixins: [ViewSelaaMixin],
  data () {
    return {
      toimittajaDialogAuki: false,
      kenttienValinnat: {},
      tableHeaders: TableHeadersToimittaja,
      menuContextValilehtiRouteName: 'ostolasku-toimittaja-nayta'
    }
  },
  created () {
    this.haeToimittajanKenttienValinnat()
  },
  methods: {
    lisaaViewKohtaisetHaunSuodattimet (query) {
      if (this.searchTerms.nimi) query.append('nimi', this.searchTerms.nimi)
      if (this.searchTerms.nro) query.append('nro', this.searchTerms.nro)
      if (this.searchTerms.paamies) query.append('paamies', this.searchTerms.paamies)
      if (this.searchTerms.tunnus) query.append('tunnus', this.searchTerms.tunnus)

      if (typeof this.searchTerms.tyyppi === 'object') {
        query = this.kasitteleMoniosuodatin(query, this.searchTerms.tyyppi)
      }

      return query
    },
    async haeToimittajanKenttienValinnat () {
      this.kenttienValinnat = await Toimittaja.haeToimittajanKenttienValinnat()
    },
    siirryToimittajanakymaan (toimittaja) {
      this.toimittajaDialogAuki = false
      this.$router.push({ name: 'ostolasku-toimittaja-nayta', params: { id: toimittaja.id } })
    },
    avaaDialogToimittaja () {
      this.toimittajaDialogAuki = true
    },
  },
}

</script>

<style lang="scss" scoped>

</style>
