<template>
  <div class="ostolasku-toimittaja-nayta">
    <ViewTopBarBase
      :title="routeTitle"
    >
      <v-menu
        bottom
        left
        nudge-bottom="40"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            color="success"
            depressed
            v-on="on"
          >
            Toiminnot
            <v-icon right>
              more_vert
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:default>
          <v-list>
            <v-list-item
              @click="toimittajaDialogAuki = true"
            >
              <v-list-item-avatar>
                <v-icon>edit</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Muokkaa toimittajaa
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="avaaDialogMuistiinpanot"
            >
              <v-list-item-avatar>
                <v-icon>anitta-icon-note</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Kirjoita muistiinpano
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
    </ViewTopBarBase>

    <Spinner v-if="tietueLataamassa" />
    <CardPahoittelu v-else-if="tietueLatausEpaonnistunut" />
    <template v-else>
      <v-container
        class="pa-2"
        fluid
      >
        <!-- Tiedot -->
        <div class="pa-4">
          <TietueenPerustiedotCard
            :paamies="toimittaja.paamies"
            :vastapuoli="toimittaja"
            vastapuoli-rooli="toimittaja"
            ei-vastapuoli-linkkia
          >
            <template v-slot:col2>
              <p class="mb-0 font-weight-bold grey--text darken-2">
                Toimittajatiedot
              </p>
              <DefList :bordered="(true)">
                <DefListItem
                  label-class="defList__label--emphasized"
                  value-class="font-weight-bold"
                >
                  <template v-slot:label>
                    Toimittajanumero
                  </template>
                  <template
                    v-if="toimittaja.nro"
                    v-slot:value
                  >
                    {{ toimittaja.nro }}
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    {{ $sovellusIdOtsake }}
                  </template>
                  <template v-slot:value>
                    {{ toimittaja.id }}
                  </template>
                </DefListItem>
                <DefListItem v-if="toimittaja.tunnus">
                  <template v-slot:label>
                    {{ tunnustyyppi }}
                  </template>
                  <template v-slot:value>
                    {{ toimittaja.tunnus }}
                  </template>
                </DefListItem>
              </DefList>
            </template>
            <template v-slot:col3>
              <p class="mb-0 font-weight-bold grey--text darken-2">
                Yhteystiedot
              </p>
              <DefList :bordered="(true)">
                <DefListItem>
                  <template v-slot:label>
                    Puhelin
                  </template>
                  <template v-slot:value>
                    <span v-if="toimittaja.puhelin">{{ toimittaja.puhelin.numero }}</span>
                  </template>
                </DefListItem>
                <DefListItem
                  value-class="text-wrap break-all"
                >
                  <template v-slot:label>
                    Sähköposti
                  </template>
                  <template
                    v-if="toimittaja.emailosoite && toimittaja.emailosoite.email"
                    v-slot:value
                  >
                    {{ toimittaja.emailosoite.email }}
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    Verkkolaskuosoite
                  </template>
                  <template
                    v-if="toimittaja.verkkolaskuosoite && toimittaja.verkkolaskuosoite.verkkolaskuosoite"
                    v-slot:value
                  >
                    {{ toimittaja.verkkolaskuosoite.verkkolaskuosoite }}
                  </template>
                </DefListItem>
                <DefListItem v-if="toimittaja.verkkolaskuosoite && toimittaja.verkkolaskuosoite.operaattori">
                  <template v-slot:label>
                    Verkkolaskuoperaattori
                  </template>
                  <template v-slot:value>
                    {{ toimittaja.verkkolaskuosoite.operaattori }}
                  </template>
                </DefListItem>
              </DefList>
            </template>
            <template v-slot:col4>
              <p class="mb-0 font-weight-bold grey--text darken-2">
                Lisätiedot
              </p>
              <DefList :bordered="(true)">
                <DefListItem>
                  <template v-slot:label>
                    Tyyppi
                  </template>
                  <template v-slot:value>
                    {{ muotoiltuTyyppi }}
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    Kieli
                  </template>
                  <template v-slot:value>
                    {{ toimittaja.kieli }}
                  </template>
                </DefListItem>
                <DefListItem v-if="toimittaja.kotipaikka">
                  <template v-slot:label>
                    Kotipaikka
                  </template>
                  <template v-slot:value>
                    {{ toimittaja.kotipaikka }}
                  </template>
                </DefListItem>
              </DefList>
            </template>
          </TietueenPerustiedotCard>
        </div>
        <div class="pa-4">
          <v-row>
            <!-- Left column -->
            <v-col
              cols="12"
              lg="8"
            >
              <!-- Laskut -->
              <div class="pb-4">
                <v-card class="pa-6">
                  <CardTitle text="Ostolaskut" />
                  <v-data-table
                    :items="ostolaskuItems"
                    :headers="tableHeadersToimittajaLaskut"
                    :hide-default-footer="ostolaskuItems.length <= 5"
                    :items-per-page="5"
                    no-data-text="Ei laskuja"
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <TableRow
                        custom-contextmenu
                        @contextmenu.prevent="avaaMenuContext($event, item, 'ostolasku-nayta')"
                      >
                        <td class="text-no-wrap">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <StatusIcon
                                v-if="item.ikoni_ja_vari"
                                :icon="item.ikoni_ja_vari.ikoni"
                                size="small"
                                :hover-icon="('open-in-new')"
                                :bg-color="item.ikoni_ja_vari.vari"
                                :event-listener="on"
                                @click="$router.push({ name: 'ostolasku-nayta', params: { id: String(item.id) } })"
                              />
                            </template>
                            <template v-slot:default>
                              <span>Avaa laskun tiedot</span>
                            </template>
                          </v-tooltip>
                        </td>
                        <td class="text-no-wrap">
                          <router-link
                            :to="{ name: 'ostolasku-nayta', params: { id: item.id } }"
                          >
                            {{ item.nro }}
                          </router-link>
                        </td>
                        <td class="max-width">
                          {{ item.tila }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.laskupaiva }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.erapaiva }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.summa | formatSumToFixed2 }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.veroton | formatSumToFixed2 }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.avoinna | formatSumToFixed2 }}
                        </td>
                      </TableRow>
                    </template>
                  </v-data-table>
                </v-card>
              </div>

              <!-- Tapahtumat -->
              <div class="py-4">
                <v-card class="pa-6">
                  <CardTitle text="Tapahtumat" />
                  <v-data-table
                    :items="tapahtumatItems"
                    :headers="tableHeadersToimittajaTapahtumat"
                    :hide-default-footer="tapahtumatItems.length <= 5"
                    :items-per-page="5"
                    no-data-text="Ei tapahtumia"
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <TableRow>
                        <td class="max-width">
                          <strong>{{ item.maksaja }}</strong>
                        </td>
                        <td class="text-no-wrap text-right">
                          <template
                            v-for="lasku in item.laskut"
                          >
                            <router-link
                              :key="lasku.id + item.id"
                              :to="{ name: 'ostolasku-nayta', params: { id: lasku.id } }"
                            >
                              <span @contextmenu.prevent="avaaMenuContext($event, item, 'ostolasku-nayta')">
                                Lasku {{ lasku.nro }}
                              </span>
                            </router-link>
                            <v-spacer :key="lasku.id" />
                          </template>
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ $date(item.kirjauspaiva) }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.viite }}
                        </td>
                        <td class="max-width">
                          {{ item.viesti }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.summa | formatSumToFixed2 }}
                        </td>
                      </TableRow>
                    </template>
                  </v-data-table>
                </v-card>
              </div>

              <!-- Muistiinpanot -->
              <div
                v-if="muistiinpanoItems.length"
                class="py-4"
              >
                <v-card class="pa-6">
                  <CardTitle text="Muistiinpanot" />

                  <v-data-table
                    :items="muistiinpanoItems"
                    :headers="tableHeadersToimittajaMuistiinpanot"
                    :hide-default-footer="muistiinpanoItems.length <= 5"
                    :items-per-page="5"
                    no-data-text="Ei muistiinpanoja"
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <TableRow>
                        <td>
                          {{ item.lahettaja }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ $date(item.aika, 'D.M.YYYY HH:mm') }}
                        </td>
                        <td class="text-no-wrap text-right">
                          <span
                            v-if="item.lasku"
                            @contextmenu.prevent="avaaMenuContext($event, { id: item.lasku.id }, 'ostolasku-nayta')"
                          >
                            <router-link
                              :to="{ name: 'ostolasku-nayta', params: { id: item.lasku.id } }"
                            >
                              Ostolasku {{ item.lasku.nro }}
                            </router-link>
                          </span>
                        </td>
                        <td class="text-wrap max-width">
                          {{ item.sisalto }}
                        </td>
                        <td>
                          <div class="d-flex flex-row">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <div v-on="on">
                                  <v-btn
                                    text
                                    icon
                                    small
                                    :disabled="!item.muokattavissa"
                                    @click="avaaDialogMuistiinpanoMuokkaa(item)"
                                  >
                                    <v-icon small>
                                      edit
                                    </v-icon>
                                  </v-btn>
                                </div>
                              </template>
                              <template
                                v-if="item.muokattavissa"
                                v-slot:default
                              >
                                <span>Muokkaa muistiinpanoa</span>
                              </template>
                              <template
                                v-else
                                v-slot:default
                              >
                                <span>Tiettyyn ostolaskuun liittyvää muistiinpanoa ei voi muokata toimittajanäkymässä</span>
                              </template>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <div v-on="on">
                                  <v-btn
                                    text
                                    icon
                                    small
                                    :disabled="!item.muokattavissa"
                                    @click="poistaMuistiinpano(item.id)"
                                  >
                                    <v-icon small>
                                      delete
                                    </v-icon>
                                  </v-btn>
                                </div>
                              </template>
                              <template
                                v-if="item.muokattavissa"
                                v-slot:default
                              >
                                <span>Poista muistiinpano</span>
                              </template>
                              <template
                                v-else
                                v-slot:default
                              >
                                <span>Tiettyyn ostolaskuun liittyvää muistiinpanoa ei voi poistaa toimittajanäkymässä</span>
                              </template>
                            </v-tooltip>
                          </div>
                        </td>
                      </TableRow>
                    </template>
                  </v-data-table>
                </v-card>
              </div>

              <!-- Viestit -->
              <div
                v-if="viestiItems.length"
                class="py-4"
              >
                <v-card class="pa-6">
                  <CardTitle text="Viestit" />

                  <v-data-table
                    :items="viestiItems"
                    :headers="tableHeadersToimittajaViestit"
                    :hide-default-footer="viestiItems.length <= 5"
                    :items-per-page="5"
                    no-data-text="Ei viestejä"
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <TableRow>
                        <td>
                          {{ item.lahettaja }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ $date(item.aika, 'D.M.YYYY HH:mm') }}
                        </td>
                        <td class="text-no-wrap text-right">
                          <span
                            v-if="item.lasku"
                            @contextmenu.prevent="avaaMenuContext($event, { id: item.lasku.id }, 'ostolasku-nayta')"
                          >
                            <router-link :to="{ name: 'ostolasku-nayta', params: { id: item.lasku.id } }">
                              Lasku {{ item.lasku.nro }}
                            </router-link>
                          </span>
                        </td>
                        <td class="text-wrap max-width">
                          <span :class="item.luettu === '–' ? 'font-weight-bold' : ''">{{ item.sisalto }}</span>
                        </td>
                        <td class="text-no-wrap">
                          {{ item.luettu }}
                        </td>
                      </TableRow>
                    </template>
                  </v-data-table>
                </v-card>
              </div>
            </v-col>

            <!-- Right column -->
            <v-col
              cols="12"
              lg="4"
            >
              <!-- Muut tiedot -->
              <v-card class="pa-6">
                <CardTitle text="Muut tiedot" />
                <ToimittajaLaskutuksenYhteenveto
                  title-class="mt-4"
                  :toimittaja="toimittaja"
                />

                <!-- Toimittajarekisterit -->
                <template v-if="toimittajarekisteriItems.length">
                  <h3 class="my-2">
                    Toimittajarekisterit
                  </h3>
                  <v-data-table
                    :items="toimittajarekisteriItems"
                    :headers="tableHeadersToimittajaToimittajarekisteri"
                    :hide-default-footer="toimittajarekisteriItems.length <= 5"
                    :items-per-page="5"
                    no-data-text="Ei toimittajarekistereitä"
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <TableRow>
                        <td class="text-no-wrap">
                          {{ item.nro }}
                        </td>
                        <td class="text-wrap">
                          {{ item.nimi }}
                        </td>
                        <td class="text-wrap">
                          {{ item.ryhma }}
                        </td>
                      </TableRow>
                    </template>
                  </v-data-table>
                </template>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </template>

    <!-- Dialog: Muistiinpanot -->
    <CardDialog
      title="Toimittajakohtaiset muistiinpanot"
      :dialog-auki="muistiinpanotDialogAuki"
      :scroll-to-bottom="(true)"
      content-class="px-4 py-2"
      @close="(muistiinpanotDialogAuki = false)"
    >
      <template v-slot:default>
        <Timeline
          v-if="muistiinpanotAikajana.length"
          class="px-4"
          :items="muistiinpanotAikajana"
          :show-title="(false)"
          :show-item-titles="(false)"
          :show-dates="(true)"
          :enable-zoom="(false)"
          :enable-direction-toggle="(false)"
          :salli-muistiinpanojen-muokkaus="false"
        />
      </template>
      <template v-slot:footer>
        <FormUusiViesti
          v-model="uusiMuistiinpanoFormData"
          :loading="uusiMuistiinpanoFormLoading"
          :focus-on-update="(true)"
          icon="note"
          msg-input-placeholder="Uusi muistiinpano"
          submit-btn-tooltip-text="Kirjoita muistiinpano"
          @submit="muistiinpanoSubmit"
        />
      </template>
    </CardDialog>

    <!-- Dialog: Muokkaa toimittajaa -->
    <CardDialog
      title="Muokkaa toimittajaa"
      :dialog-auki="toimittajaDialogAuki"
      title-class="px-12 py-6"
      content-class="px-12 py-4"
      width="1024"
      @close="(toimittajaDialogAuki = false)"
    >
      <FormToimittaja
        :kaare-objekti="toimittajaKaare"
        :kenttien-valinnat="kenttienValinnat"
        :on-auki="toimittajaDialogAuki"
        @close="(toimittajaDialogAuki = false)"
        @toimittajaTallennettu="kasittelePaivitys"
      />
    </CardDialog>

    <!-- Dialog: Muokkaa muistiinpanoa -->
    <CardDialog
      title="Muokkaa muistiinpanoa"
      :dialog-auki="muistiinpanoMuokkaaDialogAuki"
      :scroll-to-bottom="(true)"
      content-class="px-4 py-2"
      @close="(muistiinpanoMuokkaaDialogAuki = false)"
    >
      <template v-slot:footer>
        <FormUusiViesti
          v-model="muistiinpanoMuokkaaFormData"
          :loading="muistiinpanoMuokkaaFormLoading"
          :focus-on-update="(true)"
          icon="note"
          submit-btn-tooltip-text="Tallenna"
          @submit="muistiinpanoMuokkaaSubmit"
        />
      </template>
    </CardDialog>

    <!-- Dialog: Välilehti -->
    <MenuContext
      :onko-nakyvissa="menuContextNakyvissa"
      :position-x="menuContextX"
      :position-y="menuContextY"
      :vaihtoehdot="menuContextVaihtoehdot"
      @avaaValilehteen="avaaUudessaValilehdessa"
      @update:return-value="suljeMenuContext"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex'
import { Toimittaja } from '@/class/Toimittaja'
import {
  ToimittajaLaskutuksenYhteenveto,
  FormToimittaja,
  FormUusiViesti,
  TietueenPerustiedotCard,
  Timeline,
} from '@/components'
import MenuContextMixin from '@/mixins/MenuContextMixin'
import {
  TableHeadersToimittajaToimittajarekisteri,
  TableHeadersVastapuoliLaskut,
  TableHeadersVastapuoliMuistiinpanot,
  TableHeadersVastapuoliTapahtumat,
  TableHeadersVastapuoliViestit,
} from '@/utils/tableHeaders'

export default {
  name: 'ToimittajaNayta',
  components: {
    ToimittajaLaskutuksenYhteenveto,
    FormToimittaja,
    FormUusiViesti,
    TietueenPerustiedotCard,
    Timeline,
  },
  mixins: [MenuContextMixin],
  data () {
    return {
      toimittaja: {
        avoinna: null,
        ikoni_ja_vari: {
          ikoni: null,
          vari: null,
        },
        kirje_set: [],
        paamies: {
          nimi: '',
        },
        puhelin: {},
        tila: {},
      },
      toimittajaDialogAuki: false,
      kenttienValinnat: {},
      tietueLataamassa: false,
      tietueLatausEpaonnistunut: false,
      menuContextValilehtiRouteName: 'ostolasku-nayta',
      muistiinpanoMuokkaaDialogAuki: false,
      muistiinpanoMuokkaaFormData: {},
      muistiinpanoMuokkaaFormLoading: false,
      muistiinpanotDialogAuki: false,
      tableHeadersToimittajaLaskut: TableHeadersVastapuoliLaskut,
      tableHeadersToimittajaToimittajarekisteri: TableHeadersToimittajaToimittajarekisteri,
      tableHeadersToimittajaMuistiinpanot: TableHeadersVastapuoliMuistiinpanot,
      tableHeadersToimittajaTapahtumat: TableHeadersVastapuoliTapahtumat,
      tableHeadersToimittajaViestit: TableHeadersVastapuoliViestit,
      uusiMuistiinpanoFormData: {},
      uusiMuistiinpanoFormLoading: false,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    toimittajaKaare () {
      return {
        toimittaja: new Toimittaja(this.toimittaja)
      }
    },
    toimittajarekisteriItems () {
      if (!this.toimittaja.toimittajaryhmatoimittajarekisteri_set) return []

      return this.toimittaja.toimittajaryhmatoimittajarekisteri_set.map(item => {
        return {
          nro: item.toimittajarekisteri.nro,
          nimi: item.toimittajarekisteri.nimi,
          ryhma: item.ryhma,
        }
      })
    },
    ostolaskuItems () {
      if (!this.toimittaja.laskut_kayttoliittymaan) return []

      return this.toimittaja.laskut_kayttoliittymaan.map(item => {
        return {
          ...item,
          tila: item.tila.selite,
        }
      })
    },
    muistiinpanotAikajana () {
      return this.toimittaja.muistiinpanot_aikajana ? this.toimittaja.muistiinpanot_aikajana : []
    },
    muistiinpanoItems () {
      if (!this.toimittaja.muistiinpano_set) return []

      const muistiinpanot = this.toimittaja.muistiinpano_set.filter(item => {
        return !item.paamiesviesti
      })

      return muistiinpanot.map(item => {
        return {
          id: item.id,
          lahettaja: item.lahettaja.username,
          aika: item.aika,
          lasku: item.lasku,
          toimeksianto: item.toimeksianto,
          sisalto: item.data,
          muokattavissa: !item.lasku && !item.toimeksianto
        }
      })
    },
    routeTitle () {
      if (this.toimittaja.nro) {
        return `${this.$route.meta.title} ${this.toimittaja.nro}`
      } else {
        return this.$route.meta.title
      }
    },
    tapahtumatItems () {
      if (!this.toimittaja.tapahtuma_set) return []

      return this.toimittaja.tapahtuma_set
    },
    tunnustyyppi () {
      if (!this.toimittaja.tyyppi) return null

      if (this.toimittaja.tyyppi == 'H') {
        return 'Hetu'
      } else if (this.toimittaja.tyyppi == 'Y') {
        return 'Y-tunnus'
      } else {
        return 'Tunnus'
      }
    },
    muotoiltuTyyppi () {
      return this.toimittaja.tyyppi == 'Y' ? 'Yritys' : 'Henkilö'
    },
    vapaatekstiOnPitka () {
      return this.toimittaja.oletusvapaateksti.length > 100
    },
    viestiItems () {
      if (!this.toimittaja.muistiinpano_set) return []

      const viestit = this.toimittaja.muistiinpano_set.filter(item => {
        return item.paamiesviesti
      })

      return viestit.map(item => {
        return {
          lahettaja: item.lahettaja.username,
          aika: item.aika,
          lasku: item.lasku,
          sisalto: item.data,
          luettu: item.luettu ? this.$date(item.aika, 'D.M.YYYY HH:mm') : '–'
        }
      })
    },
  },
  async mounted () {
    await this.loadItem()
    await this.haeToimittajanKenttienValinnat()
  },
  methods: {
    kasittelePaivitys (toimittaja) {
      this.paivitaToimittaja(toimittaja)

      this.$naytaOnnistumisilmoitus('Asiakkaan tiedot päivitetty!')
    },
    async loadItem (silent = false) {
      try {
        if (!silent) this.tietueLataamassa = true
        const request = await this.$doRequestWithTimeout(
          this.$api.Toimittaja,
          {
            method: 'GET',
            params: {
              id: this.$route.params.id
            },
          },
          'pushRequest',
        )

        if (!request.success) throw new this.$HttpError(request)

        this.toimittaja = Object.assign({}, request.result.body)
      } catch (e) {
        if (e.request && e.request.result && e.request.result.status === 404) {
          this.$naytaVirheilmoitus('Toimittajata ei löytynyt!')

          this.$router.replace({ name: 'error404' })
        } else {
          this.tietueLatausEpaonnistunut = true
          this.$oletusVirheenkasittely(e, 'Tietojen lataaminen ei onnistunut! Yritä hetken kuluttua uudelleen.')
        }
      } finally {
        this.tietueLataamassa = false
      }
    },
    async muistiinpanoMuokkaaSubmit () {
      try {
        this.muistiinpanoMuokkaaFormLoading = true

        const requestBody = {
          data: this.muistiinpanoMuokkaaFormData.message,
        }

        const request = await this.$doRequestWithTimeout(
          this.$api.Muistiinpano,
          {
            method: 'PUT',
            url: '',
            body: requestBody,
            params: {
              id: this.muistiinpanoMuokkaaFormData.id,
            }
          },
          'doSingleRequest',
        )

        if (!request.success) throw new this.$HttpError(request)

        this.loadItem(true)
        this.muistiinpanoMuokkaaFormData = {}
        this.muistiinpanoMuokkaaDialogAuki = false

        this.$naytaOnnistumisilmoitus('Muistiinpanon muokkaus tallennettu!')
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.muistiinpanoMuokkaaFormLoading = false
      }
    },
    async muistiinpanoSubmit () {
      try {
        this.uusiMuistiinpanoFormLoading = true

        const requestBody = {
          muistiinpano: this.uusiMuistiinpanoFormData.message,
        }

        const request = await this.$doRequestWithTimeout(
          this.$api.Toimittaja,
          {
            method: 'POST',
            url: 'toiminnot/muistiinpano/',
            body: requestBody,
            params: {
              id: this.toimittaja.id,
            }
          },
          'doSingleRequest',
        )

        if (!request.success) throw new this.$HttpError(request)

        this.paivitaToimittaja(request.result.body)
        this.uusiMuistiinpanoFormData = {}
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.uusiMuistiinpanoFormLoading = false
      }
    },
    avaaDialogMuistiinpanoMuokkaa (muistiinpano) {
      this.muistiinpanoMuokkaaDialogAuki = true
      this.muistiinpanoMuokkaaFormData = {
        id: muistiinpano.id,
        message: muistiinpano.sisalto,
      }
    },
    avaaDialogMuistiinpanot () {
      this.muistiinpanotDialogAuki = true
      this.uusiMuistiinpanoFormData = {}
    },
    paivitaToimittaja (paivitettyToimittaja) {
      this.toimittaja = paivitettyToimittaja
    },
    async poistaMuistiinpano (id) {
      const result = await this.$swal({
          text: 'Haluatko varmasti poistaa muistiinpanon?',
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: 'Poista muistiinpano',
          cancelButtonText: 'Peruuta',
        })

      if (!result.value) return

      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.Muistiinpano,
          {
            method: 'DELETE',
            params: { id },
          },
          'doRequest',
        )

        if (!request.success) throw new this.$HttpError(request)

        this.loadItem(true)

        this.$naytaOnnistumisilmoitus('Muistiinpano poistettu!')
      } catch(e) {
        this.$oletusVirheenkasittely(e)
      }
    },
    async haeToimittajanKenttienValinnat () {
      this.kenttienValinnat = await Toimittaja.haeToimittajanKenttienValinnat()
    },
  }
}

</script>

<style lang="scss" scoped>

</style>
