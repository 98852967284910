var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ostolasku-nayta" },
    [
      _c(
        "ViewTopBarBase",
        { attrs: { title: _vm.routeTitle } },
        [
          _c("v-menu", {
            attrs: { bottom: "", left: "", "nudge-bottom": "40" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        { attrs: { color: "success", depressed: "" } },
                        on
                      ),
                      [
                        _vm._v(" Toiminnot "),
                        _c("v-icon", { attrs: { right: "" } }, [
                          _vm._v(" more_vert "),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "default",
                fn: function () {
                  return [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          {
                            attrs: {
                              disabled:
                                !_vm.hyvaksyMaksuunSallittu ||
                                _vm.muokkausmoodiPaalla,
                            },
                            on: { click: _vm.hyvaksyMaksuun },
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("check")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(" Hyväksy maksuun "),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          {
                            attrs: {
                              disabled:
                                !_vm.hylkaaLaskuSallittu ||
                                _vm.muokkausmoodiPaalla,
                            },
                            on: { click: _vm.hylkaaLasku },
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("cancel")])],
                              1
                            ),
                            _c("v-list-item-title", [_vm._v(" Hylkää lasku ")]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          {
                            attrs: {
                              disabled:
                                !_vm.palautaAvoimeksiSallittu ||
                                _vm.muokkausmoodiPaalla,
                            },
                            on: { click: _vm.palautaAvoimeksi },
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("keyboard_return")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(" Palauta avoimeksi "),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          {
                            attrs: { disabled: !_vm.vieMaksuunSallittu },
                            on: { click: _vm.avaaDialogVieMaksuun },
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("euro")])],
                              1
                            ),
                            _c("v-list-item-title", [_vm._v(" Vie maksuun ")]),
                          ],
                          1
                        ),
                        !_vm.ostolaskuNayta.ostolasku.tilioity
                          ? _c(
                              "v-list-item",
                              { on: { click: _vm.tiliointiHyvaksy } },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [_c("v-icon", [_vm._v("check")])],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(" Hyväksy tiliöinti "),
                                ]),
                              ],
                              1
                            )
                          : _vm.ostolaskuNayta.ostolasku.tilioity
                          ? _c(
                              "v-list-item",
                              { on: { click: _vm.tiliointiPeru } },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [_c("v-icon", [_vm._v("cancel")])],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(" Peru tiliöinti "),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.muokkausmoodiPaalla
                          ? _c(
                              "v-list-item",
                              {
                                attrs: { disabled: !_vm.muokkausSallittu },
                                on: { click: _vm.asetaMuokkausmoodi },
                              },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [_c("v-icon", [_vm._v("edit")])],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(" Muokkaa laskua "),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.muokkausSallittu && _vm.muokkausmoodiPaalla
                          ? _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.asetaMuokkausmoodi(false)
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [_c("v-icon", [_vm._v("edit")])],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(" Peruuta muutokset "),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$permission.checkSinglePerm(
                          "onlinerestapi.onlinerestapi_viesti_lahetys"
                        )
                          ? _c(
                              "v-list-item",
                              {
                                attrs: { disabled: _vm.muokkausmoodiPaalla },
                                on: { click: _vm.avaaDialogViestit },
                              },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [_c("v-icon", [_vm._v("comment")])],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(" Lähetä viesti "),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-list-item",
                          {
                            attrs: { disabled: _vm.muokkausmoodiPaalla },
                            on: { click: _vm.avaaDialogMuistiinpanot },
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("anitta-icon-note")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(" Kirjoita muistiinpano "),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          { on: { click: _vm.avaaDialogLiitteet } },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("attach_file")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(" Lisää liitteitä "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm.tietueLataamassa
        ? _c("Spinner")
        : _vm.tietueLatausEpaonnistunut
        ? _c("CardPahoittelu")
        : [
            _c(
              "v-form",
              { ref: "formMuokkaus", staticClass: "pa-0 ma-0" },
              [
                _c(
                  "v-container",
                  { staticClass: "pa-2", attrs: { fluid: "" } },
                  [
                    _c(
                      "div",
                      { staticClass: "pa-4" },
                      [
                        _c("TietueenPerustiedotCard", {
                          attrs: {
                            "card-class": _vm.ostolaskuNayta.ostolasku
                              .onko_myohassa
                              ? "yellow lighten-5"
                              : null,
                            paamies: _vm.ostolaskuNayta.ostolasku.paamies,
                            vastapuoli: _vm.ostolaskuNayta.ostolasku.toimittaja,
                            "vastapuoli-rooli": "toimittaja",
                            "vastapuoli-route": "ostolasku-toimittaja-nayta",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "col2",
                              fn: function () {
                                return [
                                  _c(
                                    "DefList",
                                    {
                                      attrs: {
                                        bordered: !_vm.muokkausmoodiPaalla,
                                      },
                                    },
                                    [
                                      _c("DefListItem", {
                                        attrs: {
                                          "label-class":
                                            "defList__label--emphasized",
                                          "value-class": "font-weight-bold",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [_vm._v(" Laskunumero ")]
                                              },
                                              proxy: true,
                                            },
                                            _vm.ostolaskuNayta.ostolasku
                                              .laskunumero
                                              ? {
                                                  key: "value",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.ostolaskuNayta
                                                              .ostolasku
                                                              .laskunumero
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                }
                                              : null,
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _c("DefListItem", {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [_vm._v(" Tila ")]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _c("StatusText", {
                                                  attrs: {
                                                    color:
                                                      _vm.ostolaskuNayta
                                                        .ostolasku.ikoni_ja_vari
                                                        .vari || "grey",
                                                    text: _vm.ostolaskuNayta
                                                      .ostolasku.tila.selite,
                                                  },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ]),
                                      }),
                                      _c("DefListItem", {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$sovellusIdOtsake
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.ostolaskuNayta
                                                        .ostolasku.id
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ]),
                                      }),
                                      !_vm.muokkausmoodiPaalla
                                        ? [
                                            _c("DefListItem", {
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [
                                                        _vm._v(" Laskupäivä "),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  _vm.ostolaskuNayta.ostolasku
                                                    .laskupaiva
                                                    ? {
                                                        key: "value",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$date(
                                                                    _vm
                                                                      .ostolaskuNayta
                                                                      .ostolasku
                                                                      .laskupaiva
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      }
                                                    : null,
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _c("DefListItem", {
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [
                                                        _vm._v(" Eräpäivä "),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  _vm.ostolaskuNayta.ostolasku
                                                    .erapaiva
                                                    ? {
                                                        key: "value",
                                                        fn: function () {
                                                          return [
                                                            _vm.ostolaskuNayta
                                                              .ostolasku
                                                              .onko_myohassa
                                                              ? _c("strong", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$date(
                                                                          _vm
                                                                            .ostolaskuNayta
                                                                            .ostolasku
                                                                            .erapaiva
                                                                        )
                                                                      ) +
                                                                      " (myöhässä) "
                                                                  ),
                                                                ])
                                                              : [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$date(
                                                                          _vm
                                                                            .ostolaskuNayta
                                                                            .ostolasku
                                                                            .erapaiva
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _vm
                                                                    .ostolaskuNayta
                                                                    .ostolasku
                                                                    .maksuehto
                                                                    ? [
                                                                        _vm._v(
                                                                          " (" +
                                                                            _vm._s(
                                                                              _vm
                                                                                .ostolaskuNayta
                                                                                .ostolasku
                                                                                .maksuehto
                                                                            ) +
                                                                            ") "
                                                                        ),
                                                                      ]
                                                                    : _vm._e(),
                                                                ],
                                                          ]
                                                        },
                                                        proxy: true,
                                                      }
                                                    : null,
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ]
                                        : _vm._e(),
                                      _vm.muokkausmoodiPaalla
                                        ? [
                                            _c("DefListItem", {
                                              staticClass:
                                                "defList__item--edit",
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [
                                                        _vm._v(" Laskupäivä "),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "value",
                                                    fn: function () {
                                                      return [
                                                        _c("v-menu", {
                                                          attrs: {
                                                            "max-width": "290",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      _vm._g(
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              dense:
                                                                                "",
                                                                              height:
                                                                                "24",
                                                                              value:
                                                                                _vm.$date(
                                                                                  _vm
                                                                                    .ostolaskuNayta
                                                                                    .muokattavaOstolasku
                                                                                    .laskupaiva
                                                                                ),
                                                                              readonly:
                                                                                "",
                                                                              rules:
                                                                                [
                                                                                  _vm
                                                                                    .$validationRules
                                                                                    .required,
                                                                                ],
                                                                            },
                                                                        },
                                                                        on
                                                                      )
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                              {
                                                                key: "default",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "v-date-picker",
                                                                      {
                                                                        attrs: {
                                                                          locale:
                                                                            "fi-FI",
                                                                          "first-day-of-week":
                                                                            "1",
                                                                          "no-title":
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.laskupaivaMenu = false
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .ostolaskuNayta
                                                                              .muokattavaOstolasku
                                                                              .laskupaiva,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .ostolaskuNayta
                                                                                  .muokattavaOstolasku,
                                                                                "laskupaiva",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "ostolaskuNayta.muokattavaOstolasku.laskupaiva",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            284792769
                                                          ),
                                                          model: {
                                                            value:
                                                              _vm.laskupaivaMenu,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.laskupaivaMenu =
                                                                $$v
                                                            },
                                                            expression:
                                                              "laskupaivaMenu",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                3668471608
                                              ),
                                            }),
                                            _c("DefListItem", {
                                              staticClass:
                                                "defList__item--edit",
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [
                                                        _vm._v(" Eräpäivä "),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "value",
                                                    fn: function () {
                                                      return [
                                                        _c("v-menu", {
                                                          attrs: {
                                                            "max-width": "290",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      _vm._g(
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              dense:
                                                                                "",
                                                                              height:
                                                                                "24",
                                                                              value:
                                                                                _vm.$date(
                                                                                  _vm
                                                                                    .ostolaskuNayta
                                                                                    .muokattavaOstolasku
                                                                                    .erapaiva
                                                                                ),
                                                                              readonly:
                                                                                "",
                                                                              rules:
                                                                                [
                                                                                  _vm
                                                                                    .$validationRules
                                                                                    .required,
                                                                                ],
                                                                            },
                                                                        },
                                                                        on
                                                                      )
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                              {
                                                                key: "default",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "v-date-picker",
                                                                      {
                                                                        attrs: {
                                                                          locale:
                                                                            "fi-FI",
                                                                          "first-day-of-week":
                                                                            "1",
                                                                          "no-title":
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.erapaivaMenu = false
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .ostolaskuNayta
                                                                              .muokattavaOstolasku
                                                                              .erapaiva,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .ostolaskuNayta
                                                                                  .muokattavaOstolasku,
                                                                                "erapaiva",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "ostolaskuNayta.muokattavaOstolasku.erapaiva",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            3027262743
                                                          ),
                                                          model: {
                                                            value:
                                                              _vm.erapaivaMenu,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.erapaivaMenu =
                                                                $$v
                                                            },
                                                            expression:
                                                              "erapaivaMenu",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                2356915825
                                              ),
                                            }),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "col3",
                              fn: function () {
                                return [
                                  _c(
                                    "DefList",
                                    {
                                      attrs: {
                                        bordered: !_vm.muokkausmoodiPaalla,
                                      },
                                    },
                                    [
                                      !_vm.muokkausmoodiPaalla
                                        ? [
                                            _c("DefListItem", {
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [_vm._v(" IBAN ")]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "value",
                                                    fn: function () {
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.ostolaskuNayta
                                                                .ostolasku
                                                                .epitieto.iban
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                1818274619
                                              ),
                                            }),
                                            _c("DefListItem", {
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [_vm._v(" BIC ")]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "value",
                                                    fn: function () {
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.ostolaskuNayta
                                                                .ostolasku
                                                                .epitieto.bic
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                485735963
                                              ),
                                            }),
                                            _c("DefListItem", {
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [_vm._v(" Viite ")]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "value",
                                                    fn: function () {
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.ostolaskuNayta
                                                                .ostolasku.viite
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                1382838154
                                              ),
                                            }),
                                            _vm.ostolaskuNayta.ostolasku
                                              .viitteemme
                                              ? _c("DefListItem", {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "label",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              " Viitteemme "
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                      {
                                                        key: "value",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .ostolaskuNayta
                                                                    .ostolasku
                                                                    .viitteemme
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2050510474
                                                  ),
                                                })
                                              : _vm._e(),
                                            _vm.ostolaskuNayta.ostolasku
                                              .viitteenne
                                              ? _c("DefListItem", {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "label",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              " Viitteenne "
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                      {
                                                        key: "value",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .ostolaskuNayta
                                                                    .ostolasku
                                                                    .viitteenne
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2945569802
                                                  ),
                                                })
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                      _vm.muokkausmoodiPaalla
                                        ? [
                                            _c("DefListItem", {
                                              staticClass:
                                                "defList__item--edit",
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [_vm._v(" IBAN ")]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "value",
                                                    fn: function () {
                                                      return [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            type: "text",
                                                            dense: "",
                                                            height: "24",
                                                            rules: [
                                                              _vm
                                                                .$validationRules
                                                                .isValidIBAN,
                                                            ],
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ostolaskuNayta
                                                                .muokattavaOstolasku
                                                                .epitieto.iban,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .ostolaskuNayta
                                                                  .muokattavaOstolasku
                                                                  .epitieto,
                                                                "iban",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ostolaskuNayta.muokattavaOstolasku.epitieto.iban",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                1153074569
                                              ),
                                            }),
                                            _c("DefListItem", {
                                              staticClass:
                                                "defList__item--edit",
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [_vm._v(" BIC ")]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "value",
                                                    fn: function () {
                                                      return [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            type: "text",
                                                            dense: "",
                                                            height: "24",
                                                            rules: [
                                                              _vm
                                                                .$validationRules
                                                                .isValidBIC,
                                                            ],
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ostolaskuNayta
                                                                .muokattavaOstolasku
                                                                .epitieto.bic,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .ostolaskuNayta
                                                                  .muokattavaOstolasku
                                                                  .epitieto,
                                                                "bic",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ostolaskuNayta.muokattavaOstolasku.epitieto.bic",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                2069621765
                                              ),
                                            }),
                                            _c("DefListItem", {
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [_vm._v(" Viite ")]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "value",
                                                    fn: function () {
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.ostolaskuNayta
                                                                .ostolasku.viite
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                1382838154
                                              ),
                                            }),
                                            _c("DefListItem", {
                                              staticClass:
                                                "defList__item--edit",
                                              attrs: {
                                                "value-class": "break-all",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [
                                                        _vm._v(" Viitteemme "),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "value",
                                                    fn: function () {
                                                      return [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            type: "text",
                                                            dense: "",
                                                            height: "24",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ostolaskuNayta
                                                                .muokattavaOstolasku
                                                                .viitteemme,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .ostolaskuNayta
                                                                  .muokattavaOstolasku,
                                                                "viitteemme",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ostolaskuNayta.muokattavaOstolasku.viitteemme",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                1570938945
                                              ),
                                            }),
                                            _c("DefListItem", {
                                              staticClass:
                                                "defList__item--edit",
                                              attrs: {
                                                "value-class": "break-all",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [
                                                        _vm._v(" Viitteenne "),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "value",
                                                    fn: function () {
                                                      return [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            type: "text",
                                                            dense: "",
                                                            height: "24",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ostolaskuNayta
                                                                .muokattavaOstolasku
                                                                .viitteenne,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .ostolaskuNayta
                                                                  .muokattavaOstolasku,
                                                                "viitteenne",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ostolaskuNayta.muokattavaOstolasku.viitteenne",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                441539969
                                              ),
                                            }),
                                          ]
                                        : _vm._e(),
                                      _c("DefListItem", {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [_vm._v(" Summa ")]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f(
                                                        "formatSumToFixed2"
                                                      )(
                                                        _vm.ostolaskuNayta
                                                          .ostolasku.summa
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ]),
                                      }),
                                    ],
                                    2
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "col4",
                              fn: function () {
                                return [
                                  _c(
                                    "DefList",
                                    { attrs: { bordered: true } },
                                    [
                                      _c("DefListItem", {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [_vm._v(" Veroton ")]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f(
                                                        "formatSumToFixed2"
                                                      )(
                                                        _vm.ostolaskuNayta
                                                          .ostolasku.veroton
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ]),
                                      }),
                                      _c("DefListItem", {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [_vm._v(" Vero ")]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f(
                                                        "formatSumToFixed2"
                                                      )(
                                                        _vm.ostolaskuNayta
                                                          .ostolasku.vero
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ]),
                                      }),
                                      _vm.ostolaskuNayta.ostolasku
                                        .viivastyskorko
                                        ? _c("DefListItem", {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " Viivästyskorko "
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "value",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.ostolaskuNayta
                                                              .ostolasku
                                                              .viivastyskorko
                                                          ) +
                                                          " % "
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              1272102346
                                            ),
                                          })
                                        : _vm._e(),
                                      _c("DefListItem", {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [_vm._v(" Avoinna ")]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f(
                                                        "formatSumToFixed2"
                                                      )(
                                                        _vm.ostolaskuNayta
                                                          .ostolasku.avoinna
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ]),
                                      }),
                                      _c("DefListItem", {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " Tiliöinti hyväksytty "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.onkoTilioity) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "pa-4" },
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "12", lg: "8" } }, [
                              _c(
                                "div",
                                { staticClass: "pb-4" },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "pa-6" },
                                    [
                                      _c("CardTitle", {
                                        attrs: { text: "Laskun kuva" },
                                      }),
                                      _vm.ostolaskuNayta.laskunKuvaData
                                        ? _c("div", [
                                            _c("div", [
                                              _c(
                                                "object",
                                                {
                                                  attrs: {
                                                    data: _vm.ostolaskuNayta
                                                      .laskunKuvaData,
                                                    type: "application/pdf",
                                                    height: _vm.user
                                                      .tiivisNakyma
                                                      ? "400px"
                                                      : "500px",
                                                    width: "100%",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "iframe",
                                                    {
                                                      attrs: {
                                                        src: _vm.ostolaskuNayta
                                                          .laskunKuvaData,
                                                        height: _vm.user
                                                          .tiivisNakyma
                                                          ? "400px"
                                                          : "500px",
                                                        width: "100%",
                                                      },
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          "Selaimesi ei tue PDF:n näyttämistä"
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ])
                                        : _c("p", [
                                            _vm._v(
                                              " Laskulle ei ole ladattu kuvaa "
                                            ),
                                          ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "py-4" },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "pa-6" },
                                    [
                                      _c("CardTitle", {
                                        attrs: { text: "Laskurivit" },
                                      }),
                                      !_vm.muokkausmoodiPaalla
                                        ? _c("v-data-table", {
                                            attrs: {
                                              items: _vm.laskuriviItems,
                                              headers:
                                                _vm.tableHeadersLaskurivit,
                                              "hide-default-footer":
                                                _vm.laskuriviItems.length <= 10,
                                              "no-data-text": "Ei laskurivejä",
                                              dense: _vm.user.tiivisNakyma,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "top",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "v-toolbar",
                                                        {
                                                          attrs: {
                                                            flat: "",
                                                            short: "",
                                                            dense:
                                                              _vm.user
                                                                .tiivisNakyma,
                                                          },
                                                        },
                                                        [
                                                          _c("span"),
                                                          _c("v-spacer"),
                                                          !_vm.muokkausmoodiPaalla
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "ml-2",
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "primary",
                                                                    disabled:
                                                                      !_vm.muokkausSallittu,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.asetaMuokkausmoodi,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        left: "",
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " edit "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " Muokkaa laskua "
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "item",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("TableRow", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.koodi
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "max-width",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.selite
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.alvp
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatSumToFixed2"
                                                                  )(item.ahinta)
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$muotoileDesimaaliluku(
                                                                    item.maara
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatSumToFixed2"
                                                                  )(
                                                                    item.veroton
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatSumToFixed2"
                                                                  )(item.vero)
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatSumToFixed2"
                                                                  )(
                                                                    item.verollinen
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                                _vm.laskuriviItems.length
                                                  ? {
                                                      key: "body.append",
                                                      fn: function () {
                                                        return [
                                                          _c("TableRow", [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-no-wrap text-right",
                                                                attrs: {
                                                                  colspan: "5",
                                                                },
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    "Yhteensä"
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-right",
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "formatSumToFixed2"
                                                                      )(
                                                                        _vm
                                                                          .ostolaskuNayta
                                                                          .ostolasku
                                                                          .veroton
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-no-wrap text-right",
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "formatSumToFixed2"
                                                                      )(
                                                                        _vm
                                                                          .ostolaskuNayta
                                                                          .ostolasku
                                                                          .vero
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-no-wrap text-right",
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "formatSumToFixed2"
                                                                      )(
                                                                        _vm
                                                                          .ostolaskuNayta
                                                                          .ostolasku
                                                                          .summa
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    }
                                                  : null,
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm.muokkausmoodiPaalla
                                        ? [
                                            _c(
                                              "v-toolbar",
                                              {
                                                attrs: {
                                                  flat: "",
                                                  short: "",
                                                  dense: _vm.user.tiivisNakyma,
                                                },
                                              },
                                              [
                                                _c("span"),
                                                _c("v-spacer"),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "ml-2",
                                                    attrs: {
                                                      small: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.asetaMuokkausmoodi(
                                                          false
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          left: "",
                                                          small: "",
                                                        },
                                                      },
                                                      [_vm._v(" cancel ")]
                                                    ),
                                                    _vm._v(
                                                      " Peruuta muutokset "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "ml-2",
                                                    attrs: {
                                                      small: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.tallennaMuutokset,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          left: "",
                                                          small: "",
                                                        },
                                                      },
                                                      [_vm._v(" check ")]
                                                    ),
                                                    _vm._v(
                                                      " Tallenna muutokset "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("LaskuriviTable", {
                                              staticClass: "px-4 py-2",
                                              attrs: {
                                                "cell-size": "small",
                                                tyyppi: "ostolasku",
                                                tuoterekisteri: false,
                                              },
                                              on: {
                                                kayttajasyote: function (
                                                  $event
                                                ) {
                                                  _vm.laskurivejaMuokattu = true
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.ostolaskuNayta
                                                    .muokattavaOstolasku,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.ostolaskuNayta,
                                                    "muokattavaOstolasku",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "ostolaskuNayta.muokattavaOstolasku",
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "py-4" },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "pa-6" },
                                    [
                                      _c("CardTitle", {
                                        attrs: { text: "Tiliöinti" },
                                      }),
                                      _c("v-data-table", {
                                        attrs: {
                                          items: _vm.tiliointiItems,
                                          headers: _vm.tableHeadersTilioinnit,
                                          "hide-default-footer":
                                            _vm.ostolaskuNayta.ostolasku
                                              .ostolaskurivi_set.length <= 10,
                                          "no-data-text": "Ei rivejä",
                                          dense: _vm.user.tiivisNakyma,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "top",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "v-toolbar",
                                                  {
                                                    attrs: {
                                                      flat: "",
                                                      short: "",
                                                      dense:
                                                        _vm.user.tiivisNakyma,
                                                    },
                                                  },
                                                  [
                                                    _c("DefListItem", {
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _vm._v(
                                                                " Tiliöinti hyväksytty "
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                        {
                                                          key: "value",
                                                          fn: function () {
                                                            return [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.onkoTilioity
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ]),
                                                    }),
                                                    _c("span"),
                                                    _c("v-spacer"),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: {
                                                          small: "",
                                                          color: "primary",
                                                          disabled:
                                                            _vm.ostolaskuNayta
                                                              .ostolasku
                                                              .tilioity,
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.tiliointiPalauta,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              left: "",
                                                              small: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " keyboard_return "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " Palauta esitiliöinti "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    !_vm.ostolaskuNayta
                                                      .ostolasku.tilioity
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: {
                                                              small: "",
                                                              color: "primary",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.tiliointiHyvaksy,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  left: "",
                                                                  small: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " check "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " Hyväksy tiliöinti "
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm.ostolaskuNayta
                                                          .ostolasku.tilioity
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: {
                                                              small: "",
                                                              color: "primary",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.tiliointiPeru,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  left: "",
                                                                  small: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " cancel "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " Peru tiliöinti "
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "item.tili",
                                            fn: function (props) {
                                              return [
                                                _c(
                                                  "v-edit-dialog",
                                                  {
                                                    ref:
                                                      "editDialog-" +
                                                      props.item.id,
                                                    attrs: {
                                                      "return-value":
                                                        props.item.tili,
                                                    },
                                                    on: {
                                                      "update:returnValue":
                                                        function ($event) {
                                                          return _vm.$set(
                                                            props.item,
                                                            "tili",
                                                            $event
                                                          )
                                                        },
                                                      "update:return-value":
                                                        function ($event) {
                                                          return _vm.$set(
                                                            props.item,
                                                            "tili",
                                                            $event
                                                          )
                                                        },
                                                      save: function ($event) {
                                                        return _vm.tallennaTiliointirivi(
                                                          props.item
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "input",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "v-autocomplete",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm
                                                                        .ostolaskuNayta
                                                                        .ostolasku
                                                                        .tilioity,
                                                                    items:
                                                                      _vm.tiliointitiliItems,
                                                                    "item-text":
                                                                      "tunnus",
                                                                    "item-value":
                                                                      "id",
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.suljeEditDialogJaTallennaRivi(
                                                                          props.item
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      props.item
                                                                        .tiliointitili_id,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          props.item,
                                                                          "tiliointitili_id",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "props.item.tiliointitili_id",
                                                                  },
                                                                }
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.muotoileTiliointitili(
                                                            props.item
                                                              .tiliointitili_id
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.summa",
                                            fn: function (props) {
                                              return [
                                                _c("v-edit-dialog", {
                                                  attrs: {
                                                    "return-value":
                                                      props.item.summa,
                                                  },
                                                  on: {
                                                    "update:returnValue":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          props.item,
                                                          "summa",
                                                          $event
                                                        )
                                                      },
                                                    "update:return-value":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          props.item,
                                                          "summa",
                                                          $event
                                                        )
                                                      },
                                                    save: function ($event) {
                                                      _vm.$refs[
                                                        "textFieldSumma-" +
                                                          props.item.id
                                                      ].valid
                                                        ? _vm.tallennaTiliointirivi(
                                                            props.item
                                                          )
                                                        : function () {}
                                                    },
                                                    open: function ($event) {
                                                      return _vm.tallennaTiliointirivinAlkupArvo(
                                                        props.item.summa
                                                      )
                                                    },
                                                    cancel: function ($event) {
                                                      props.item.summa =
                                                        _vm.palautaTiliointirivinAlkupArvo()
                                                    },
                                                    close: function ($event) {
                                                      _vm.$refs[
                                                        "textFieldSumma-" +
                                                          props.item.id
                                                      ].valid
                                                        ? function () {}
                                                        : _vm.$nextTick(
                                                            function () {
                                                              props.item.summa =
                                                                _vm.palautaTiliointirivinAlkupArvo()
                                                            }
                                                          )
                                                    },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatSumToFixed2"
                                                                  )(
                                                                    props.item
                                                                      .summa
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                      {
                                                        key: "input",
                                                        fn: function () {
                                                          return [
                                                            _c("v-text-field", {
                                                              ref:
                                                                "textFieldSumma-" +
                                                                props.item.id,
                                                              attrs: {
                                                                disabled:
                                                                  _vm
                                                                    .ostolaskuNayta
                                                                    .ostolasku
                                                                    .tilioity,
                                                                type: "number",
                                                                rules: [
                                                                  _vm.$validationRules.maxDecimalPlaces(
                                                                    props.item
                                                                      .summa,
                                                                    2
                                                                  ),
                                                                  _vm
                                                                    .$validationRules
                                                                    .requiredLoose,
                                                                ],
                                                                "single-line":
                                                                  "",
                                                              },
                                                              model: {
                                                                value:
                                                                  props.item
                                                                    .summa,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      props.item,
                                                                      "summa",
                                                                      _vm._n(
                                                                        $$v
                                                                      )
                                                                    )
                                                                  },
                                                                expression:
                                                                  "props.item.summa",
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.alvp",
                                            fn: function (props) {
                                              return [
                                                _c("v-edit-dialog", {
                                                  attrs: {
                                                    "return-value":
                                                      props.item.alvp,
                                                  },
                                                  on: {
                                                    "update:returnValue":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          props.item,
                                                          "alvp",
                                                          $event
                                                        )
                                                      },
                                                    "update:return-value":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          props.item,
                                                          "alvp",
                                                          $event
                                                        )
                                                      },
                                                    save: function ($event) {
                                                      _vm.$refs[
                                                        "textFieldAlvp-" +
                                                          props.item.id
                                                      ].valid
                                                        ? _vm.tallennaTiliointirivi(
                                                            props.item
                                                          )
                                                        : function () {}
                                                    },
                                                    open: function ($event) {
                                                      return _vm.tallennaTiliointirivinAlkupArvo(
                                                        props.item.alvp
                                                      )
                                                    },
                                                    cancel: function ($event) {
                                                      props.item.alvp =
                                                        _vm.palautaTiliointirivinAlkupArvo()
                                                    },
                                                    close: function ($event) {
                                                      _vm.$refs[
                                                        "textFieldAlvp-" +
                                                          props.item.id
                                                      ].valid
                                                        ? function () {}
                                                        : _vm.$nextTick(
                                                            function () {
                                                              props.item.alvp =
                                                                _vm.palautaTiliointirivinAlkupArvo()
                                                            }
                                                          )
                                                    },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$muotoileDesimaaliluku(
                                                                    props.item
                                                                      .alvp
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                      {
                                                        key: "input",
                                                        fn: function () {
                                                          return [
                                                            _c("v-text-field", {
                                                              ref:
                                                                "textFieldAlvp-" +
                                                                props.item.id,
                                                              attrs: {
                                                                disabled:
                                                                  _vm
                                                                    .ostolaskuNayta
                                                                    .ostolasku
                                                                    .tilioity,
                                                                type: "number",
                                                                rules: [
                                                                  _vm.$validationRules.maxDecimalPlaces(
                                                                    props.item
                                                                      .alvp,
                                                                    2
                                                                  ),
                                                                  _vm
                                                                    .$validationRules
                                                                    .requiredLoose,
                                                                ],
                                                                "single-line":
                                                                  "",
                                                              },
                                                              model: {
                                                                value:
                                                                  props.item
                                                                    .alvp,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      props.item,
                                                                      "alvp",
                                                                      _vm._n(
                                                                        $$v
                                                                      )
                                                                    )
                                                                  },
                                                                expression:
                                                                  "props.item.alvp",
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.selite",
                                            fn: function (props) {
                                              return [
                                                _c(
                                                  "v-edit-dialog",
                                                  {
                                                    attrs: {
                                                      "return-value":
                                                        props.item.selite,
                                                    },
                                                    on: {
                                                      "update:returnValue":
                                                        function ($event) {
                                                          return _vm.$set(
                                                            props.item,
                                                            "selite",
                                                            $event
                                                          )
                                                        },
                                                      "update:return-value":
                                                        function ($event) {
                                                          return _vm.$set(
                                                            props.item,
                                                            "selite",
                                                            $event
                                                          )
                                                        },
                                                      save: function ($event) {
                                                        return _vm.tallennaTiliointirivi(
                                                          props.item
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "input",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm
                                                                        .ostolaskuNayta
                                                                        .ostolasku
                                                                        .tilioity,
                                                                    "single-line":
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      props.item
                                                                        .selite,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          props.item,
                                                                          "selite",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "props.item.selite",
                                                                  },
                                                                }
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          props.item.selite
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.laskuliitteetLkm
                                ? _c(
                                    "div",
                                    { staticClass: "py-4" },
                                    [
                                      _c(
                                        "v-card",
                                        { staticClass: "pa-6" },
                                        [
                                          _c("CardTitle", {
                                            attrs: {
                                              text: "Liitteet ja tiedostot",
                                            },
                                          }),
                                          _c("DataTableLaskuliite", {
                                            attrs: {
                                              items:
                                                _vm.ostolaskuNayta.ostolasku
                                                  .laskuliite_set,
                                              lasku:
                                                _vm.ostolaskuNayta.ostolasku,
                                              tyyppi: "ostolasku",
                                            },
                                            on: {
                                              "tiedosto-poistettu": function (
                                                $event
                                              ) {
                                                return _vm.loadItem(true)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.lisatiedotItems.length ||
                              _vm.ostolaskuNayta.ostolasku.toimitusosoite
                                ? _c(
                                    "div",
                                    { staticClass: "py-4" },
                                    [
                                      _c(
                                        "v-card",
                                        { staticClass: "pa-6" },
                                        [
                                          _c("CardTitle", {
                                            attrs: { text: "Lisätiedot" },
                                          }),
                                          _vm.lisatiedotItems.length
                                            ? [
                                                _c("v-data-table", {
                                                  attrs: {
                                                    items: _vm.lisatiedotItems,
                                                    headers:
                                                      _vm.tableHeadersLisatiedot,
                                                    "hide-default-footer": true,
                                                    "items-per-page": 999,
                                                    "no-data-text":
                                                      "Ei lisätietoja",
                                                    dense: "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "item",
                                                        fn: function (ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c("TableRow", [
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-no-wrap",
                                                                },
                                                                [
                                                                  _c("strong", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.key
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "max-width",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.value
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1355340415
                                                  ),
                                                }),
                                              ]
                                            : _vm._e(),
                                          _vm.ostolaskuNayta.ostolasku
                                            .toimitusosoite
                                            ? [
                                                _c(
                                                  "h3",
                                                  { staticClass: "pt-4 pb-2" },
                                                  [_vm._v(" Toimitusosoite ")]
                                                ),
                                                _vm.ostolaskuNayta.ostolasku
                                                  .toimitusosoite
                                                  ? _c("OsoiteKappale", {
                                                      staticClass: "mb-2",
                                                      attrs: {
                                                        saaja: _vm
                                                          .ostolaskuNayta
                                                          .ostolasku
                                                          .toimitusosoite.saaja
                                                          ? _vm.ostolaskuNayta
                                                              .ostolasku
                                                              .toimitusosoite
                                                              .saaja
                                                          : "",
                                                        postiosoite:
                                                          _vm.ostolaskuNayta
                                                            .ostolasku
                                                            .toimitusosoite
                                                            .osoite,
                                                        postinumero:
                                                          _vm.ostolaskuNayta
                                                            .ostolasku
                                                            .toimitusosoite
                                                            .numero,
                                                        postitoimipaikka:
                                                          _vm.ostolaskuNayta
                                                            .ostolasku
                                                            .toimitusosoite
                                                            .toimipaikka,
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm.ostolaskuNayta.ostolasku
                                                  .toimitustapa
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "Toimitustapa: " +
                                                          _vm._s(
                                                            _vm.ostolaskuNayta
                                                              .ostolasku
                                                              .toimitustapa
                                                          )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", lg: "4" } },
                              [
                                _c(
                                  "v-card",
                                  { staticClass: "pa-6" },
                                  [
                                    _c("Timeline", {
                                      attrs: {
                                        items:
                                          _vm.ostolaskuNayta.ostolasku.aikajana,
                                        "enable-reply": true,
                                      },
                                      on: {
                                        "msg-mark-as-unread": function (
                                          $event
                                        ) {
                                          return _vm.loadItem(true)
                                        },
                                        "msg-mark-as-read": function ($event) {
                                          return _vm.loadItem(true)
                                        },
                                        reply: _vm.avaaDialogViestit,
                                        "muistiinpano-muokkaa-submit":
                                          function ($event) {
                                            return _vm.loadItem(true)
                                          },
                                        "muistiinpano-poistettu": function (
                                          $event
                                        ) {
                                          return _vm.loadItem(true)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.muokkausmoodiPaalla,
                            expression: "muokkausmoodiPaalla",
                          },
                        ],
                        staticClass: "fixed-card py-4",
                        attrs: { flat: "" },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mx-4 elevation-8",
                            attrs: { large: "" },
                            on: {
                              click: function ($event) {
                                return _vm.asetaMuokkausmoodi(false)
                              },
                            },
                          },
                          [_vm._v(" Peruuta muutokset ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mx-2 elevation-8",
                            attrs: { large: "", color: "success" },
                            on: { click: _vm.tallennaMuutokset },
                          },
                          [_vm._v(" Tallenna muutokset ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
      _c("CardDialog", {
        attrs: {
          title: _vm.$t("common:sendMessageHeader", {
            asiaa: "ostolaskunumeroa " + _vm.ostolaskuNayta.ostolasku.nro,
          }),
          "dialog-auki": _vm.uusiViestiDialogAuki,
          "scroll-to-bottom": true,
          "content-class": "px-4 py-2",
        },
        on: {
          close: function ($event) {
            _vm.uusiViestiDialogAuki = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _vm.viestit.length
                  ? _c("Timeline", {
                      staticClass: "px-4",
                      attrs: {
                        items: _vm.viestit,
                        "show-title": false,
                        "show-item-titles": false,
                        "show-dates": true,
                        "enable-zoom": false,
                        "enable-direction-toggle": false,
                      },
                      on: {
                        "msg-mark-as-unread": function ($event) {
                          return _vm.loadItem(true)
                        },
                        "msg-mark-as-read": function ($event) {
                          return _vm.loadItem(true)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("FormUusiViesti", {
                  attrs: {
                    loading: _vm.uusiViestiFormLoading,
                    "focus-on-update": true,
                    painikkeet: "",
                  },
                  on: {
                    submit: _vm.viestiSubmit,
                    close: function ($event) {
                      _vm.uusiViestiDialogAuki = false
                    },
                  },
                  model: {
                    value: _vm.ostolaskuNayta.uusiViestiFormData,
                    callback: function ($$v) {
                      _vm.$set(_vm.ostolaskuNayta, "uusiViestiFormData", $$v)
                    },
                    expression: "ostolaskuNayta.uusiViestiFormData",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("CardDialog", {
        attrs: {
          title: "Muistiinpanot",
          "dialog-auki": _vm.uusiMuistiinpanoDialogAuki,
          "scroll-to-bottom": true,
          "content-class": "px-4 py-2",
        },
        on: {
          close: function ($event) {
            _vm.uusiMuistiinpanoDialogAuki = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _vm.muistiinpanot.length
                  ? _c("Timeline", {
                      staticClass: "px-4",
                      attrs: {
                        items: _vm.muistiinpanot,
                        "show-title": false,
                        "show-item-titles": false,
                        "show-dates": true,
                        "enable-zoom": false,
                        "enable-direction-toggle": false,
                      },
                      on: {
                        "muistiinpano-muokkaa-submit": function ($event) {
                          return _vm.loadItem(true)
                        },
                        "muistiinpano-poistettu": function ($event) {
                          return _vm.loadItem(true)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("FormUusiViesti", {
                  attrs: {
                    loading: _vm.uusiMuistiinpanoFormLoading,
                    "focus-on-update": true,
                    icon: "note",
                    "msg-input-placeholder": "Uusi muistiinpano",
                    "submit-btn-tooltip-text": "Kirjoita muistiinpano",
                    painikkeet: "",
                  },
                  on: {
                    submit: _vm.muistiinpanoSubmit,
                    close: function ($event) {
                      _vm.uusiMuistiinpanoDialogAuki = false
                    },
                  },
                  model: {
                    value: _vm.ostolaskuNayta.uusiMuistiinpanoFormData,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.ostolaskuNayta,
                        "uusiMuistiinpanoFormData",
                        $$v
                      )
                    },
                    expression: "ostolaskuNayta.uusiMuistiinpanoFormData",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "CardDialog",
        {
          attrs: {
            title: _vm.$t("common:attachmentAdd"),
            "dialog-auki": _vm.liitteetDialogAuki,
          },
          on: {
            close: function ($event) {
              _vm.liitteetDialogAuki = false
            },
          },
        },
        [
          _c("FormLiitteet", {
            staticClass: "pa-4",
            attrs: { loading: _vm.liitteetFormLoading },
            on: {
              cancel: function ($event) {
                _vm.liitteetDialogAuki = false
              },
              submit: _vm.liitteetSubmit,
              close: function ($event) {
                _vm.liitteetDialogAuki = false
              },
            },
            model: {
              value: _vm.ostolaskuNayta.liitteetFormData,
              callback: function ($$v) {
                _vm.$set(_vm.ostolaskuNayta, "liitteetFormData", $$v)
              },
              expression: "ostolaskuNayta.liitteetFormData",
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Vie lasku maksuun",
            "dialog-auki": _vm.vieMaksuunDialogAuki,
          },
          on: {
            close: function ($event) {
              _vm.vieMaksuunDialogAuki = false
            },
          },
        },
        [
          _c("FormVieMaksuun", {
            staticClass: "pa-4",
            attrs: {
              "on-auki": _vm.vieMaksuunDialogAuki,
              loading: _vm.vieMaksuunFormLoading,
              ostolasku: _vm.ostolaskuNayta.ostolasku,
              paamies: _vm.ostolaskuNayta.ostolasku.paamies,
              "ostotili-items": _vm.ostolaskuNayta.ostotiliItems,
            },
            on: {
              cancel: function ($event) {
                _vm.vieMaksuunDialogAuki = false
              },
              close: function ($event) {
                _vm.vieMaksuunDialogAuki = false
              },
              submit: _vm.vieMaksuun,
            },
            model: {
              value: _vm.ostolaskuNayta.vieMaksuunFormData,
              callback: function ($$v) {
                _vm.$set(_vm.ostolaskuNayta, "vieMaksuunFormData", $$v)
              },
              expression: "ostolaskuNayta.vieMaksuunFormData",
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Anna selite",
            "dialog-auki": _vm.laskurivejaMuokattuDialogAuki,
          },
          on: {
            close: function ($event) {
              _vm.laskurivejaMuokattuDialogAuki = false
            },
          },
        },
        [
          _c(
            "FormSeliteMuokkaukselle",
            {
              staticClass: "pa-4",
              attrs: {
                "on-auki": _vm.laskurivejaMuokattuDialogAuki,
                loading: _vm.laskurivejaMuokattuFormLoading,
              },
              on: {
                cancel: function ($event) {
                  _vm.laskurivejaMuokattuDialogAuki = false
                },
                "peruuta-muutokset":
                  _vm.peruutaMuutoksetJaSuljeLaskurivejaMuokattuDialog,
                submit: _vm.kasitteleLaskurivejaMuokattuSubmit,
              },
              model: {
                value: _vm.ostolaskuNayta.laskurivejaMuokattuFormData,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.ostolaskuNayta,
                    "laskurivejaMuokattuFormData",
                    $$v
                  )
                },
                expression: "ostolaskuNayta.laskurivejaMuokattuFormData",
              },
            },
            [
              _c("v-card", { staticClass: "mx-2 pa-4 mb-8 warning" }, [
                _vm._v(
                  " Ostolaskun laskurivejä on muokattu. Anna selite laskurivien muokkaukselle. "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }