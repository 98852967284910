<template>
  <div class="ostolasku-selaa">
    <!-- Off-canvas -->
    <v-navigation-drawer
      ref="offCanvas"
      v-model="showOffCanvas"
      width="450"
      app
      right
      temporary
    >
      <Spinner v-if="loadingOffCanvas" />
      <OffCanvasOstolasku
        v-else
        v-model="selectedItem.item"
        @close-button-click="setShowOffCanvas"
        @messageSubmit="refresh"
        @msg-mark-as-unread="refresh"
        @msg-mark-as-read="refresh"
      />
    </v-navigation-drawer>

    <!-- Top bar -->
    <ViewSelaaTopBarBase
      pikahaun-placeholder-teksti="Hae (laskunumero, ID, viite)"
      :vuex-module-name="vuexModuleName"
      @open-search-panel="openSearchPanel"
      @close-search-panel="closeSearchPanel"
    />

    <!-- Tabs -->
    <ViewSelaaTabs
      :vuex-module-name="vuexModuleName"
    />

    <!-- Advanced search -->
    <TransitionSlidePanel>
      <div
        v-show="searchPanelOpen"
        class="pa-1 primary darken-1 shadow--inset"
      >
        <InfoValittuValilehti
          :current-tab="currentTab"
        />
        <FormSearchLasku
          v-model="searchTerms"
          :ostolasku="true"
        />
      </div>
    </TransitionSlidePanel>

    <!-- Toolbar -->
    <ViewSelaaToolbar
      :vuex-module-name="vuexModuleName"
      item-partitiivi="laskua"
    >
    </ViewSelaaToolbar>

    <ViewSelaaToolbar
      class="mt-n4"
      :vuex-module-name="vuexModuleName"
      :lataa-csv-btn-config="{ csvData, csvLabels, csvTitle }"
      :alt-selaa-btn-config="{ toName: 'ostolasku-toimittaja-selaa', itemPartitiivi: 'toimittajia' }"
      :uusi-btn-config="{ toName: 'ostolasku-uusi', itemName: 'ostolasku' }"
      item-partitiivi="laskua"
      :hakutulokset="(false)"
    >
      <template v-slot:toolbar-vasen>
        <MenuOstolaskutoiminnot
          v-if="naytaCheckboxit"
          :disabled="!checkedItems.length"
          :item-count="checkedItems.length"
          :hyvaksy-maksuun-sallittu="hyvaksyMaksuunSallittu"
          :hylkaa-lasku-sallittu="hylkaaLaskuSallittu"
          :palauta-avoimeksi-sallittu="palautaAvoimeksiSallittu"
          :vie-maksuun-sallittu="vieMaksuunSallittu"
          @hyvaksyMaksuun="hyvaksyMaksuun"
          @hylkaa="hylkaa"
          @palautaAvoimeksi="palautaAvoimeksi"
          @vieMaksuun="avaaVieMaksuunDialog"
        />
        <v-spacer />
      </template>
      <template v-slot:btn-keskella>
        <ViewSelaaToolbarBtn
          :to="{ name: 'ostolasku-maksutoimeksianto-selaa' }"
          icon="view_list"
        >
          Selaa maksutoimeksiantoja
        </ViewSelaaToolbarBtn>
      </template>
    </ViewSelaaToolbar>

    <!-- Data Table -->
    <v-data-table
      v-model="checkedItems"
      class="v-data-table--striped"
      :headers="tableHeaders"
      :items="items"
      :hide-default-footer="items.length < 10"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50, 100],
      }"
      :multi-sort="true"
      :server-items-length="itemsTotal"
      :options.sync="pagination"
      :loading="loading"
      item-key="id"
      loading-text=""
      no-data-text=""
      :show-select="naytaCheckboxit"
      :dense="tiivisNakyma"
    >
      <template v-slot:progress>
        <v-progress-linear
          color="primary"
          indeterminate
        />
      </template>
      <template v-slot:item="{ item }">
        <TableRow
          :checked-alternative-color="(checkedItems.some(checkedItem => checkedItem.id == item.id))"
          :clickable="(true)"
          :data="item"
          :selected-item="selectedItem.item"
          :fade="loading"
          :yellow="item.onko_myohassa"
          :custom-contextmenu="(true)"
          @contextmenu.prevent="avaaMenuContext($event, item)"
        >
          <td v-if="naytaCheckboxit">
            <v-checkbox
              color="primary"
              :input-value="checkedItems.some(checkedItem => checkedItem.id == item.id)"
              @change="kasitteleValinta($event, item)"
            />
          </td>
          <td
            class="text-no-wrap"
            @click="setSelectedItem({ item })"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <StatusIcon
                  v-if="item.ikoni_ja_vari"
                  :icon="item.ikoni_ja_vari.ikoni"
                  :size="tiivisNakyma ? 'small' : 'medium'"
                  :hover-icon="('open-in-new')"
                  :bg-color="item.ikoni_ja_vari.vari"
                  :event-listener="on"
                  @click="$router.push({ name: 'ostolasku-nayta', params: { id: item.id } })"
                />
              </template>
              <template v-slot:default>
                <span>Avaa lasku</span>
              </template>
            </v-tooltip>
          </td>
          <td
            class="text-no-wrap"
            @click="setSelectedItem({ item })"
          >
            {{ item.nro }}
          </td>
          <td
            class="text-no-wrap"
            @click="setSelectedItem({ item })"
          >
            {{ item.id }}
          </td>
          <td
            class="max-width"
            @click="setSelectedItem({ item })"
          >
            {{ item.toimittaja.nimi }} <template v-if="item.toimittaja.id">
              ({{ item.toimittaja.id }})
            </template>
          </td>
          <td
            class="text-no-wrap"
            @click="setSelectedItem({ item })"
          >
            {{ item.tila.selite }}
          </td>
          <td
            class="text-no-wrap"
            @click="setSelectedItem({ item })"
          >
            {{ item.paamies.nimi }} <template v-if="item.paamies.id">
              ({{ item.paamies.id }})
            </template>
          </td>
          <td
            class="text-no-wrap text-right"
            @click="setSelectedItem({ item })"
          >
            {{ $date(item.laskupaiva) }}
          </td>
          <td
            class="text-no-wrap text-right"
            @click="setSelectedItem({ item })"
          >
            {{ $date(item.erapaiva) }}
          </td>
          <td
            class="text-no-wrap text-right"
            @click="setSelectedItem({ item })"
          >
            {{ item.summa | formatSumToFixed2 }}
          </td>
        </TableRow>
      </template>
    </v-data-table>

    <TyhjaTaulukkoOtsikko v-if="tyhjaTaulukko"
      :otsikko="$t('youHaveNoRepeatingInvoices')"
    >
    </TyhjaTaulukkoOtsikko>
    <ToolbarTyhjaTaulukko v-if="tyhjaTaulukko"
      ikoni="library_add"
      taustavari="success"
      :teksti="$t('newRepeatingInvoice')"
      :infoteksti="$t('noRepeatingInvoiceAddInfo')"
      :naytaAina="true"
      :uusi-btn-config="{ toName: 'ostolasku-uusi' }"
    >
    </ToolbarTyhjaTaulukko>
    <!-- Bottom toolbar -->
    <v-toolbar
      v-if="(itemsTotal >= 16)"
      class="my-6 pa-2 transparent"
      flat
    >
      <MenuOstolaskutoiminnot
        v-if="naytaCheckboxit"
        :disabled="!checkedItems.length"
        :item-count="checkedItems.length"
        :hyvaksy-maksuun-sallittu="hyvaksyMaksuunSallittu"
        :hylkaa-lasku-sallittu="hylkaaLaskuSallittu"
        :palauta-avoimeksi-sallittu="palautaAvoimeksiSallittu"
        :vie-maksuun-sallittu="vieMaksuunSallittu"
        @hyvaksyMaksuun="hyvaksyMaksuun"
        @hylkaa="hylkaa"
        @palautaAvoimeksi="palautaAvoimeksi"
        @vieMaksuun="avaaVieMaksuunDialog"
      />
      <v-spacer />
      <Pagination
        v-if="(pagesTotal > 1)"
        v-model="pagination.page"
        :length="pagesTotal"
        :disabled="loading"
      />
      <v-spacer />
    </v-toolbar>

    <!-- Välilehti-dialog -->
    <MenuContext
      :onko-nakyvissa="menuContextNakyvissa"
      :position-x="menuContextX"
      :position-y="menuContextY"
      :vaihtoehdot="menuContextVaihtoehdot"
      @avaaValilehteen="avaaUudessaValilehdessa"
      @update:return-value="suljeMenuContext"
    />

    <!-- Dialog: Vie maksuun -->
    <CardDialog
      title="Vie laskuja maksuun"
      :dialog-auki="vieMaksuunDialogAuki"
      @close="(vieMaksuunDialogAuki = false)"
    >
      <FormVieMaksuun
        v-model="vieMaksuunFormData"
        class="pa-4"
        :on-auki="vieMaksuunDialogAuki"
        :loading="vieMaksuunFormLoading"
        :ostolaskut="maksuunVietavatOstolaskut"
        :paamies="maksuunVietavienPaamies"
        :ostotili-items="maksuunVietavienPaamiehenOstotiliItems"
        @cancel="vieMaksuunDialogAuki = false"
        @close="(vieMaksuunDialogAuki = false)"
        @submit="vieOstolaskutMaksuun"
      />
    </CardDialog>
  </div>
</template>

<script>

import _ from 'lodash'
import {
  FormSearchLasku,
  FormVieMaksuun,
  InfoValittuValilehti,
  MenuOstolaskutoiminnot,
  OffCanvasOstolasku,
  Pagination,
  ToolbarTyhjaTaulukko,
  TyhjaTaulukkoOtsikko,
  TableRow,
  TransitionSlidePanel,
  ViewSelaaTabs,
  ViewSelaaToolbar,
  ViewSelaaToolbarBtn,
  ViewSelaaTopBarBase,
} from '@/components'
import ViewSelaaMixin from '@/mixins/ViewSelaaMixin'
import { TableHeadersOstolaskut } from '@/utils/tableHeaders'

export default {
  name: 'OstolaskuSelaa',
  components: {
    FormSearchLasku,
    FormVieMaksuun,
    InfoValittuValilehti,
    MenuOstolaskutoiminnot,
    OffCanvasOstolasku,
    Pagination,
    TableRow,
    TransitionSlidePanel,
    ViewSelaaTabs,
    ViewSelaaToolbar,
    ViewSelaaToolbarBtn,
    ViewSelaaTopBarBase,
    ToolbarTyhjaTaulukko,
    TyhjaTaulukkoOtsikko,
  },
  mixins: [ViewSelaaMixin],
  data () {
    return {
      checkedItems: [],
      tableHeaders: TableHeadersOstolaskut,
      menuContextValilehtiRouteName: 'ostolasku-nayta',
      vieMaksuunDialogAuki: false,
      vieMaksuunFormLoading: false,
    }
  },
  computed: {
    naytaCheckboxit () {
      return this.currentTab === 'avoinna' || this.currentTab === 'hyvaksyttyMaksuun'
    },
    hyvaksyMaksuunSallittu () {
      return this.currentTab === 'avoinna'
    },
    hylkaaLaskuSallittu () {
      return this.currentTab === 'avoinna'
    },
    palautaAvoimeksiSallittu () {
      return this.currentTab === 'hyvaksyttyMaksuun'
    },
    vieMaksuunSallittu () {
      return this.currentTab === 'hyvaksyttyMaksuun'
    },
    csvLabels () {
      return {
          nro: { title: 'Laskunumero' },
          id: { title:  this.$sovellusIdOtsake },
          toimittaja: { title: 'Toimittaja' },
          tila: { title: 'Tila' },
          ...(!this.vainYksiPaamies && { paamies: { title: 'Päämies' } }),
          laskupaiva: { title: 'Laskupäivä' },
          erapaiva: { title: 'Eräpäivä' },
          summa: { title: 'Summa' },
      }
    },
    csvData () {
      return this.items.map(item => {
        return {
            nro: item.nro,
            id: item.id,
            toimittaja: item.toimittaja.nimi,
            tila: item.tila.selite,
            ...(!this.vainYksiPaamies && { paamies: `${item.paamies.nimi} (${item.paamies.id})` }),
            laskupaiva: item.laskupaiva,
            erapaiva: item.erapaiva,
            summa: this.$formatSumToFixed2(item.summa),
        }
      })
    },
    csvTitle () {
      return 'Ostolaskut_haku-' + this.$dateFnsFormat(new Date(), 'YYYY-MM-DD-HHmm-ss')
    },
    maksuunVietavatOstolaskut: {
      get () {
        return this.$store.state[this.vuexModuleName].maksuunVietavatOstolaskut
      },
      set (val) {
        this.$store.commit(`${this.vuexModuleName}/setMaksuunVietavatOstolaskut`, val)
      }
    },
    maksuunVietavienPaamies: {
      get () {
        return this.$store.state[this.vuexModuleName].maksuunVietavienPaamies
      },
      set (val) {
        this.$store.commit(`${this.vuexModuleName}/setMaksuunVietavienPaamies`, val)
      }
    },
    maksuunVietavienPaamiehenOstotiliItems: {
      get () {
        return this.$store.state[this.vuexModuleName].maksuunVietavienPaamiehenOstotiliItems
      },
      set (val) {
        this.$store.commit(`${this.vuexModuleName}/setMaksuunVietavienPaamiehenOstotiliItems`, val)
      }
    },
    vieMaksuunFormData: {
      get () {
        return this.$store.state[this.vuexModuleName].vieMaksuunFormData
      },
      set (val) {
        this.$store.commit(`${this.vuexModuleName}/setVieMaksuunFormData`, val)
      }
    },
  },
  watch: {
    currentTab () {
      this.checkedItems = []
    }
  },
  methods: {
    kasitteleValinta (checked, item) {
      if (checked) {
        this.checkedItems.push(item)
      } else {
        const taulukko = this.checkedItems
        _.remove(taulukko, (checkedItem) => {
          return checkedItem.id === item.id
        })
        this.checkedItems = []
        this.$nextTick(() => {
          this.checkedItems = taulukko
        })
      }
    },
    async hyvaksyMaksuun () {
      this.teeMassatoiminto({
        actionName: 'hyvaksyMaksuun',
        onnistumisteksti: 'hyväksytty maksuun'
      })
    },
    async teeMassatoiminto ({ actionName = '', onnistumisteksti = '' }) {
      this.loading = true

      try {
        const kasiteltavienIdt = this.checkedItems.map((item) => {
          return item.id
        })
        const kasiteltujenLkm = await this.$store.dispatch(`${this.vuexModuleName}/${actionName}`, kasiteltavienIdt)

        const sanamuoto = kasiteltujenLkm === 1 ? 'lasku' : 'laskua'
        this.$naytaOnnistumisilmoitus(`${kasiteltujenLkm} ${sanamuoto} ${onnistumisteksti}.`)
        this.checkedItems = []
        await this.loadItems()
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.loading = false
      }
    },
    hylkaa () {
      this.teeMassatoiminto({
        actionName: 'hylkaa',
        onnistumisteksti: 'hylätty'
      })
    },
    palautaAvoimeksi () {
      this.teeMassatoiminto({
        actionName: 'palautaAvoimeksi',
        onnistumisteksti: 'palautettu avoimeksi'
      })
    },
    avaaVieMaksuunDialog () {
      this.$store.commit(`${this.vuexModuleName}/palautaMaksuunVientiMuuttujatAlkutilaan`)
      this.maksuunVietavatOstolaskut = this.checkedItems

      if (this.onkoLaskujaUsealtaEriPaamiehelta()) return

      this.maksuunVietavienPaamies = this.maksuunVietavatOstolaskut[0].paamies
      this.suodataPaamiehenOstotilit()
      this.vieMaksuunDialogAuki = true
    },
    onkoLaskujaUsealtaEriPaamiehelta () {
      let onUsealta = false
      let edellinenPaamies = null
      this.maksuunVietavatOstolaskut.map((ostolasku) => {
        if (edellinenPaamies !== null && edellinenPaamies.id !== ostolasku.paamies.id) {
          this.$naytaVirheilmoitus('Ei voi viedä maksuun laskuja usealta eri päämieheltä.')
          onUsealta = true
          return
        } else {
          edellinenPaamies = ostolasku.paamies
        }
      })
      return onUsealta
    },
    suodataPaamiehenOstotilit () {
      const kayttajanPaamiestenTilit = this.$store.state.yleinen.ostotiliItems
      this.maksuunVietavienPaamiehenOstotiliItems = kayttajanPaamiestenTilit.filter(tili => {
        return tili.paamies_set.includes(this.maksuunVietavienPaamies.id)
      })
    },
    async vieOstolaskutMaksuun () {
      this.loading = true
      this.vieMaksuunDialogAuki = false

      try {
        const {
          onnistuneetLkm,
          epaonnistuneet
        } = await this.$store.dispatch(`${this.vuexModuleName}/vieOstolaskutMaksuun`)

        const sanamuoto = onnistuneetLkm === 1 ? 'lasku' : 'laskua'
        if (onnistuneetLkm > 0) this.$naytaOnnistumisilmoitus(`${onnistuneetLkm} ${sanamuoto} viety maksuun.`)

        if (epaonnistuneet.length) {
          const epaonnistuneetIdt = epaonnistuneet.map(ostolasku => {
            return ostolasku.id
          })
          const epaonnistuneetStr = epaonnistuneetIdt.join(', ')
          this.$naytaVirheilmoitus(`Seuraavien laskujen maksuun vienti epäonnistui: (id:) ${epaonnistuneetStr}.`)
        }

        if (onnistuneetLkm > 0) {
          await this.$store.dispatch(`${this.vuexModuleName}/tilitaOstolaskut`)
          this.checkedItems = []
          await this.loadItems()
        }
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.loading = false
      }
    },
    lisaaViewKohtaisetHaunSuodattimet (query) {
      if (this.searchTerms.laskunumero) query.append('nro', this.searchTerms.laskunumero)
      if (this.searchTerms.viite) query.append('viite', this.searchTerms.viite)
      if (this.searchTerms.viitteemme) query.append('viitteemme', this.searchTerms.viitteemme)
      if (this.searchTerms.viitteenne) query.append('viitteenne', this.searchTerms.viitteenne)
      if (this.searchTerms.pmViite) query.append('pm_viite', this.searchTerms.pmViite)
      if (this.searchTerms.sopimus) query.append('sopimus', this.searchTerms.sopimus)
      if (this.searchTerms.toimittaja) query.append('toimittaja', this.searchTerms.toimittaja)
      if (this.searchTerms.paamies) query.append('paamies', this.searchTerms.paamies)
      if (this.searchTerms.laskupaivaAlkaen) query.append('laskupaiva_after', this.searchTerms.laskupaivaAlkaen)
      if (this.searchTerms.laskupaivaPaattyen) query.append('laskupaiva_before', this.searchTerms.laskupaivaPaattyen)
      if (this.searchTerms.erapaivaAlkaen) query.append('erapaiva_after', this.searchTerms.erapaivaAlkaen)
      if (this.searchTerms.erapaivaPaattyen) query.append('erapaiva_before', this.searchTerms.erapaivaPaattyen)

      if (typeof this.searchTerms.tila === 'object') {
        query = this.kasitteleMoniosuodatin(query, this.searchTerms.tila)
      }
      if (typeof this.searchTerms.toimittajaTyyppi === 'object') {
        query = this.kasitteleMoniosuodatin(query, this.searchTerms.toimittajaTyyppi)
      }

      return query
    },
  }
}

</script>

<style lang="scss" scoped>

/* Korjaa marginin joka kasvattaa data-tablen rivien korkeutta */
.v-input--selection-controls {
  margin: 0 0px -16px -4px;
}

</style>
