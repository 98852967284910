var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ostolasku-selaa" },
    [
      _c(
        "v-navigation-drawer",
        {
          ref: "offCanvas",
          attrs: { width: "450", app: "", right: "", temporary: "" },
          model: {
            value: _vm.showOffCanvas,
            callback: function ($$v) {
              _vm.showOffCanvas = $$v
            },
            expression: "showOffCanvas",
          },
        },
        [
          _vm.loadingOffCanvas
            ? _c("Spinner")
            : _c("OffCanvasOstolasku", {
                on: {
                  "close-button-click": _vm.setShowOffCanvas,
                  messageSubmit: _vm.refresh,
                  "msg-mark-as-unread": _vm.refresh,
                  "msg-mark-as-read": _vm.refresh,
                },
                model: {
                  value: _vm.selectedItem.item,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectedItem, "item", $$v)
                  },
                  expression: "selectedItem.item",
                },
              }),
        ],
        1
      ),
      _c("ViewSelaaTopBarBase", {
        attrs: {
          "pikahaun-placeholder-teksti": "Hae (laskunumero, ID, viite)",
          "vuex-module-name": _vm.vuexModuleName,
        },
        on: {
          "open-search-panel": _vm.openSearchPanel,
          "close-search-panel": _vm.closeSearchPanel,
        },
      }),
      _c("ViewSelaaTabs", {
        attrs: { "vuex-module-name": _vm.vuexModuleName },
      }),
      _c("TransitionSlidePanel", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.searchPanelOpen,
                expression: "searchPanelOpen",
              },
            ],
            staticClass: "pa-1 primary darken-1 shadow--inset",
          },
          [
            _c("InfoValittuValilehti", {
              attrs: { "current-tab": _vm.currentTab },
            }),
            _c("FormSearchLasku", {
              attrs: { ostolasku: true },
              model: {
                value: _vm.searchTerms,
                callback: function ($$v) {
                  _vm.searchTerms = $$v
                },
                expression: "searchTerms",
              },
            }),
          ],
          1
        ),
      ]),
      _c("ViewSelaaToolbar", {
        attrs: {
          "vuex-module-name": _vm.vuexModuleName,
          "item-partitiivi": "laskua",
        },
      }),
      _c("ViewSelaaToolbar", {
        staticClass: "mt-n4",
        attrs: {
          "vuex-module-name": _vm.vuexModuleName,
          "lataa-csv-btn-config": {
            csvData: _vm.csvData,
            csvLabels: _vm.csvLabels,
            csvTitle: _vm.csvTitle,
          },
          "alt-selaa-btn-config": {
            toName: "ostolasku-toimittaja-selaa",
            itemPartitiivi: "toimittajia",
          },
          "uusi-btn-config": {
            toName: "ostolasku-uusi",
            itemName: "ostolasku",
          },
          "item-partitiivi": "laskua",
          hakutulokset: false,
        },
        scopedSlots: _vm._u([
          {
            key: "toolbar-vasen",
            fn: function () {
              return [
                _vm.naytaCheckboxit
                  ? _c("MenuOstolaskutoiminnot", {
                      attrs: {
                        disabled: !_vm.checkedItems.length,
                        "item-count": _vm.checkedItems.length,
                        "hyvaksy-maksuun-sallittu": _vm.hyvaksyMaksuunSallittu,
                        "hylkaa-lasku-sallittu": _vm.hylkaaLaskuSallittu,
                        "palauta-avoimeksi-sallittu":
                          _vm.palautaAvoimeksiSallittu,
                        "vie-maksuun-sallittu": _vm.vieMaksuunSallittu,
                      },
                      on: {
                        hyvaksyMaksuun: _vm.hyvaksyMaksuun,
                        hylkaa: _vm.hylkaa,
                        palautaAvoimeksi: _vm.palautaAvoimeksi,
                        vieMaksuun: _vm.avaaVieMaksuunDialog,
                      },
                    })
                  : _vm._e(),
                _c("v-spacer"),
              ]
            },
            proxy: true,
          },
          {
            key: "btn-keskella",
            fn: function () {
              return [
                _c(
                  "ViewSelaaToolbarBtn",
                  {
                    attrs: {
                      to: { name: "ostolasku-maksutoimeksianto-selaa" },
                      icon: "view_list",
                    },
                  },
                  [_vm._v(" Selaa maksutoimeksiantoja ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("v-data-table", {
        staticClass: "v-data-table--striped",
        attrs: {
          headers: _vm.tableHeaders,
          items: _vm.items,
          "hide-default-footer": _vm.items.length < 10,
          "footer-props": {
            itemsPerPageOptions: [10, 25, 50, 100],
          },
          "multi-sort": true,
          "server-items-length": _vm.itemsTotal,
          options: _vm.pagination,
          loading: _vm.loading,
          "item-key": "id",
          "loading-text": "",
          "no-data-text": "",
          "show-select": _vm.naytaCheckboxit,
          dense: _vm.tiivisNakyma,
        },
        on: {
          "update:options": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "progress",
            fn: function () {
              return [
                _c("v-progress-linear", {
                  attrs: { color: "primary", indeterminate: "" },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "item",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "TableRow",
                  {
                    attrs: {
                      "checked-alternative-color": _vm.checkedItems.some(
                        function (checkedItem) {
                          return checkedItem.id == item.id
                        }
                      ),
                      clickable: true,
                      data: item,
                      "selected-item": _vm.selectedItem.item,
                      fade: _vm.loading,
                      yellow: item.onko_myohassa,
                      "custom-contextmenu": true,
                    },
                    on: {
                      contextmenu: function ($event) {
                        $event.preventDefault()
                        return _vm.avaaMenuContext($event, item)
                      },
                    },
                  },
                  [
                    _vm.naytaCheckboxit
                      ? _c(
                          "td",
                          [
                            _c("v-checkbox", {
                              attrs: {
                                color: "primary",
                                "input-value": _vm.checkedItems.some(function (
                                  checkedItem
                                ) {
                                  return checkedItem.id == item.id
                                }),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.kasitteleValinta($event, item)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "td",
                      {
                        staticClass: "text-no-wrap",
                        on: {
                          click: function ($event) {
                            return _vm.setSelectedItem({ item: item })
                          },
                        },
                      },
                      [
                        _c("v-tooltip", {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    item.ikoni_ja_vari
                                      ? _c("StatusIcon", {
                                          attrs: {
                                            icon: item.ikoni_ja_vari.ikoni,
                                            size: _vm.tiivisNakyma
                                              ? "small"
                                              : "medium",
                                            "hover-icon": "open-in-new",
                                            "bg-color": item.ikoni_ja_vari.vari,
                                            "event-listener": on,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$router.push({
                                                name: "ostolasku-nayta",
                                                params: { id: item.id },
                                              })
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                },
                              },
                              {
                                key: "default",
                                fn: function () {
                                  return [_c("span", [_vm._v("Avaa lasku")])]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-no-wrap",
                        on: {
                          click: function ($event) {
                            return _vm.setSelectedItem({ item: item })
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.nro) + " ")]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-no-wrap",
                        on: {
                          click: function ($event) {
                            return _vm.setSelectedItem({ item: item })
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.id) + " ")]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "max-width",
                        on: {
                          click: function ($event) {
                            return _vm.setSelectedItem({ item: item })
                          },
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(item.toimittaja.nimi) + " "),
                        item.toimittaja.id
                          ? [_vm._v(" (" + _vm._s(item.toimittaja.id) + ") ")]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-no-wrap",
                        on: {
                          click: function ($event) {
                            return _vm.setSelectedItem({ item: item })
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.tila.selite) + " ")]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-no-wrap",
                        on: {
                          click: function ($event) {
                            return _vm.setSelectedItem({ item: item })
                          },
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(item.paamies.nimi) + " "),
                        item.paamies.id
                          ? [_vm._v(" (" + _vm._s(item.paamies.id) + ") ")]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-no-wrap text-right",
                        on: {
                          click: function ($event) {
                            return _vm.setSelectedItem({ item: item })
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$date(item.laskupaiva)) + " ")]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-no-wrap text-right",
                        on: {
                          click: function ($event) {
                            return _vm.setSelectedItem({ item: item })
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$date(item.erapaiva)) + " ")]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-no-wrap text-right",
                        on: {
                          click: function ($event) {
                            return _vm.setSelectedItem({ item: item })
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("formatSumToFixed2")(item.summa)) +
                            " "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.checkedItems,
          callback: function ($$v) {
            _vm.checkedItems = $$v
          },
          expression: "checkedItems",
        },
      }),
      _vm.tyhjaTaulukko
        ? _c("TyhjaTaulukkoOtsikko", {
            attrs: { otsikko: _vm.$t("youHaveNoRepeatingInvoices") },
          })
        : _vm._e(),
      _vm.tyhjaTaulukko
        ? _c("ToolbarTyhjaTaulukko", {
            attrs: {
              ikoni: "library_add",
              taustavari: "success",
              teksti: _vm.$t("newRepeatingInvoice"),
              infoteksti: _vm.$t("noRepeatingInvoiceAddInfo"),
              naytaAina: true,
              "uusi-btn-config": { toName: "ostolasku-uusi" },
            },
          })
        : _vm._e(),
      _vm.itemsTotal >= 16
        ? _c(
            "v-toolbar",
            { staticClass: "my-6 pa-2 transparent", attrs: { flat: "" } },
            [
              _vm.naytaCheckboxit
                ? _c("MenuOstolaskutoiminnot", {
                    attrs: {
                      disabled: !_vm.checkedItems.length,
                      "item-count": _vm.checkedItems.length,
                      "hyvaksy-maksuun-sallittu": _vm.hyvaksyMaksuunSallittu,
                      "hylkaa-lasku-sallittu": _vm.hylkaaLaskuSallittu,
                      "palauta-avoimeksi-sallittu":
                        _vm.palautaAvoimeksiSallittu,
                      "vie-maksuun-sallittu": _vm.vieMaksuunSallittu,
                    },
                    on: {
                      hyvaksyMaksuun: _vm.hyvaksyMaksuun,
                      hylkaa: _vm.hylkaa,
                      palautaAvoimeksi: _vm.palautaAvoimeksi,
                      vieMaksuun: _vm.avaaVieMaksuunDialog,
                    },
                  })
                : _vm._e(),
              _c("v-spacer"),
              _vm.pagesTotal > 1
                ? _c("Pagination", {
                    attrs: { length: _vm.pagesTotal, disabled: _vm.loading },
                    model: {
                      value: _vm.pagination.page,
                      callback: function ($$v) {
                        _vm.$set(_vm.pagination, "page", $$v)
                      },
                      expression: "pagination.page",
                    },
                  })
                : _vm._e(),
              _c("v-spacer"),
            ],
            1
          )
        : _vm._e(),
      _c("MenuContext", {
        attrs: {
          "onko-nakyvissa": _vm.menuContextNakyvissa,
          "position-x": _vm.menuContextX,
          "position-y": _vm.menuContextY,
          vaihtoehdot: _vm.menuContextVaihtoehdot,
        },
        on: {
          avaaValilehteen: _vm.avaaUudessaValilehdessa,
          "update:return-value": _vm.suljeMenuContext,
        },
      }),
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Vie laskuja maksuun",
            "dialog-auki": _vm.vieMaksuunDialogAuki,
          },
          on: {
            close: function ($event) {
              _vm.vieMaksuunDialogAuki = false
            },
          },
        },
        [
          _c("FormVieMaksuun", {
            staticClass: "pa-4",
            attrs: {
              "on-auki": _vm.vieMaksuunDialogAuki,
              loading: _vm.vieMaksuunFormLoading,
              ostolaskut: _vm.maksuunVietavatOstolaskut,
              paamies: _vm.maksuunVietavienPaamies,
              "ostotili-items": _vm.maksuunVietavienPaamiehenOstotiliItems,
            },
            on: {
              cancel: function ($event) {
                _vm.vieMaksuunDialogAuki = false
              },
              close: function ($event) {
                _vm.vieMaksuunDialogAuki = false
              },
              submit: _vm.vieOstolaskutMaksuun,
            },
            model: {
              value: _vm.vieMaksuunFormData,
              callback: function ($$v) {
                _vm.vieMaksuunFormData = $$v
              },
              expression: "vieMaksuunFormData",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }