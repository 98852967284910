var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ostolasku-maksutoimeksianto-selaa" },
    [
      _c("ViewSelaaTopBarBase", {
        attrs: {
          "pikahaun-placeholder-teksti": "Hae (tiedoston nimi, ID)",
          "vuex-module-name": _vm.vuexModuleName,
          "search-panel": false,
        },
        on: {
          "open-search-panel": _vm.openSearchPanel,
          "close-search-panel": _vm.closeSearchPanel,
        },
      }),
      _c("ViewSelaaToolbar", {
        attrs: {
          "vuex-module-name": _vm.vuexModuleName,
          "item-partitiivi": "maksutoimeksiantoa",
          "alt-selaa-btn-config": {
            toName: "ostolasku-selaa",
            itemPartitiivi: "ostolaskuja",
          },
        },
      }),
      _c("v-data-table", {
        staticClass: "v-data-table--striped",
        attrs: {
          headers: _vm.tableHeaders,
          items: _vm.items,
          "hide-default-footer": _vm.items.length < 10,
          "footer-props": {
            itemsPerPageOptions: [10, 25, 50, 100],
          },
          "multi-sort": true,
          "server-items-length": _vm.itemsTotal,
          options: _vm.pagination,
          loading: _vm.loading,
          "loading-text": "",
          "no-data-text": "",
          dense: _vm.tiivisNakyma,
        },
        on: {
          "update:options": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "progress",
            fn: function () {
              return [
                _c("v-progress-linear", {
                  attrs: { color: "primary", indeterminate: "" },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "item",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "TableRow",
                  {
                    attrs: { clickable: true, data: item, fade: _vm.loading },
                    on: {
                      click: function ($event) {
                        return _vm.lataaMaksutoimeksiantotiedosto(item)
                      },
                    },
                  },
                  [
                    _c(
                      "td",
                      { staticClass: "text-no-wrap" },
                      [
                        _c("v-tooltip", {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _c("StatusIcon", {
                                      attrs: {
                                        icon: "invoice-draft",
                                        size: _vm.tiivisNakyma
                                          ? "small"
                                          : "medium",
                                        "hover-icon": "file-download",
                                        "bg-color": "primary",
                                        "event-listener": on,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.lataaMaksutoimeksiantotiedosto(
                                            item
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("span", [_vm._v("Lataa tiedosto")]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                    _c("td", { staticClass: "max-width" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.kasitteleTiedostonNimi(item.tiedosto)) +
                          " "
                      ),
                    ]),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(" " + _vm._s(_vm.$date(item.luotu)) + " "),
                    ]),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(" " + _vm._s(item.id) + " "),
                    ]),
                    _c(
                      "td",
                      { staticClass: "text-no-wrap" },
                      [
                        item.lahetetty
                          ? _c("span", [
                              _vm._v(
                                "Lähetetty " + _vm._s(_vm.$date(item.lahetetty))
                              ),
                            ])
                          : _c(
                              "v-btn",
                              {
                                attrs: { small: "", color: "primary" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.lahetaTiedostoNetboxiin(item.id)
                                  },
                                },
                              },
                              [
                                _vm._v(" Lähetä "),
                                _c(
                                  "v-icon",
                                  { attrs: { right: "", "x-small": "" } },
                                  [_vm._v(" send ")]
                                ),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _vm.pagesTotal > 1
        ? _c(
            "v-toolbar",
            { staticClass: "my-6 py-2 transparent", attrs: { flat: "" } },
            [
              _c("v-spacer"),
              _c("Pagination", {
                attrs: { length: _vm.pagesTotal, disabled: _vm.loading },
                model: {
                  value: _vm.pagination.page,
                  callback: function ($$v) {
                    _vm.$set(_vm.pagination, "page", $$v)
                  },
                  expression: "pagination.page",
                },
              }),
              _c("v-spacer"),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }