<template>
  <div class="ostolasku-nayta">
    <ViewTopBarBase
      :title="routeTitle"
    >
      <v-menu
        bottom
        left
        nudge-bottom="40"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            color="success"
            depressed
            v-on="on"
          >
            Toiminnot
            <v-icon right>
              more_vert
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:default>
          <v-list>
            <v-list-item
              :disabled="!hyvaksyMaksuunSallittu || muokkausmoodiPaalla"
              @click="hyvaksyMaksuun"
            >
              <v-list-item-avatar>
                <v-icon>check</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Hyväksy maksuun
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="!hylkaaLaskuSallittu || muokkausmoodiPaalla"
              @click="hylkaaLasku"
            >
              <v-list-item-avatar>
                <v-icon>cancel</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Hylkää lasku
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="!palautaAvoimeksiSallittu || muokkausmoodiPaalla"
              @click="palautaAvoimeksi"
            >
              <v-list-item-avatar>
                <v-icon>keyboard_return</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Palauta avoimeksi
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="!vieMaksuunSallittu"
              @click="avaaDialogVieMaksuun"
            >
              <v-list-item-avatar>
                <v-icon>euro</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Vie maksuun
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="!ostolaskuNayta.ostolasku.tilioity"
              @click="tiliointiHyvaksy"
            >
              <v-list-item-avatar>
                <v-icon>check</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Hyväksy tiliöinti
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-else-if="ostolaskuNayta.ostolasku.tilioity"
              @click="tiliointiPeru"
            >
              <v-list-item-avatar>
                <v-icon>cancel</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Peru tiliöinti
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="!muokkausmoodiPaalla"
              :disabled="!muokkausSallittu"
              @click="asetaMuokkausmoodi"
            >
              <v-list-item-avatar>
                <v-icon>edit</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Muokkaa laskua
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="muokkausSallittu && muokkausmoodiPaalla"
              @click="asetaMuokkausmoodi(false)"
            >
              <v-list-item-avatar>
                <v-icon>edit</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Peruuta muutokset
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="$permission.checkSinglePerm('onlinerestapi.onlinerestapi_viesti_lahetys')"
              :disabled="muokkausmoodiPaalla"
              @click="avaaDialogViestit"
            >
              <v-list-item-avatar>
                <v-icon>comment</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Lähetä viesti
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="muokkausmoodiPaalla"
              @click="avaaDialogMuistiinpanot"
            >
              <v-list-item-avatar>
                <v-icon>anitta-icon-note</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Kirjoita muistiinpano
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="avaaDialogLiitteet"
            >
              <v-list-item-avatar>
                <v-icon>attach_file</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Lisää liitteitä
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
    </ViewTopBarBase>

    <Spinner v-if="tietueLataamassa" />
    <CardPahoittelu v-else-if="tietueLatausEpaonnistunut" />
    <template v-else>
      <v-form
        ref="formMuokkaus"
        class="pa-0 ma-0"
      >
        <v-container
          class="pa-2"
          fluid
        >
          <!-- Tiedot -->
          <div class="pa-4">
            <TietueenPerustiedotCard
              :card-class="(ostolaskuNayta.ostolasku.onko_myohassa ? 'yellow lighten-5' : null)"
              :paamies="ostolaskuNayta.ostolasku.paamies"
              :vastapuoli="ostolaskuNayta.ostolasku.toimittaja"
              vastapuoli-rooli="toimittaja"
              vastapuoli-route="ostolasku-toimittaja-nayta"
            >
              <template v-slot:col2>
                <DefList :bordered="!muokkausmoodiPaalla">
                  <DefListItem
                    label-class="defList__label--emphasized"
                    value-class="font-weight-bold"
                  >
                    <template v-slot:label>
                      Laskunumero
                    </template>
                    <template
                      v-if="ostolaskuNayta.ostolasku.laskunumero"
                      v-slot:value
                    >
                      {{ ostolaskuNayta.ostolasku.laskunumero }}
                    </template>
                  </DefListItem>
                  <DefListItem>
                    <template v-slot:label>
                      Tila
                    </template>
                    <template v-slot:value>
                      <StatusText
                        :color="ostolaskuNayta.ostolasku.ikoni_ja_vari.vari || 'grey'"
                        :text="ostolaskuNayta.ostolasku.tila.selite"
                      />
                    </template>
                  </DefListItem>
                  <DefListItem>
                    <template v-slot:label>
                      {{ $sovellusIdOtsake }}
                    </template>
                    <template v-slot:value>
                      {{ ostolaskuNayta.ostolasku.id }}
                    </template>
                  </DefListItem>
                  <template v-if="!muokkausmoodiPaalla">
                    <DefListItem>
                      <template v-slot:label>
                        Laskupäivä
                      </template>
                      <template
                        v-if="ostolaskuNayta.ostolasku.laskupaiva"
                        v-slot:value
                      >
                        {{ $date(ostolaskuNayta.ostolasku.laskupaiva) }}
                      </template>
                    </DefListItem>
                    <DefListItem>
                      <template v-slot:label>
                        Eräpäivä
                      </template>
                      <template
                        v-if="ostolaskuNayta.ostolasku.erapaiva"
                        v-slot:value
                      >
                        <strong v-if="ostolaskuNayta.ostolasku.onko_myohassa">
                          {{ $date(ostolaskuNayta.ostolasku.erapaiva) }} (myöhässä)
                        </strong>
                        <template v-else>
                          {{ $date(ostolaskuNayta.ostolasku.erapaiva) }} <template v-if="ostolaskuNayta.ostolasku.maksuehto">
                            ({{ ostolaskuNayta.ostolasku.maksuehto }})
                          </template>
                        </template>
                      </template>
                    </DefListItem>
                  </template>
                  <template v-if="muokkausmoodiPaalla">
                    <DefListItem class="defList__item--edit">
                      <template v-slot:label>
                        Laskupäivä
                      </template>
                      <template v-slot:value>
                        <v-menu
                          v-model="laskupaivaMenu"
                          max-width="290"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              dense
                              height="24"
                              :value="$date(ostolaskuNayta.muokattavaOstolasku.laskupaiva)"
                              readonly
                              :rules="[$validationRules.required]"
                              v-on="on"
                            />
                          </template>
                          <template v-slot:default>
                            <v-date-picker
                              v-model="ostolaskuNayta.muokattavaOstolasku.laskupaiva"
                              locale="fi-FI"
                              first-day-of-week="1"
                              no-title
                              @change="laskupaivaMenu = false"
                            />
                          </template>
                        </v-menu>
                      </template>
                    </DefListItem>
                    <DefListItem class="defList__item--edit">
                      <template v-slot:label>
                        Eräpäivä
                      </template>
                      <template v-slot:value>
                        <v-menu
                          v-model="erapaivaMenu"
                          max-width="290"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              dense
                              height="24"
                              :value="$date(ostolaskuNayta.muokattavaOstolasku.erapaiva)"
                              readonly
                              :rules="[$validationRules.required]"
                              v-on="on"
                            />
                          </template>
                          <template v-slot:default>
                            <v-date-picker
                              v-model="ostolaskuNayta.muokattavaOstolasku.erapaiva"
                              locale="fi-FI"
                              first-day-of-week="1"
                              no-title
                              @change="erapaivaMenu = false"
                            />
                          </template>
                        </v-menu>
                      </template>
                    </DefListItem>
                  </template>
                </DefList>
              </template>
              <template v-slot:col3>
                <DefList :bordered="!muokkausmoodiPaalla">
                  <template v-if="!muokkausmoodiPaalla">
                    <DefListItem>
                      <template v-slot:label>
                        IBAN
                      </template>
                      <template v-slot:value>
                        {{ ostolaskuNayta.ostolasku.epitieto.iban }}
                      </template>
                    </DefListItem>
                    <DefListItem>
                      <template v-slot:label>
                        BIC
                      </template>
                      <template v-slot:value>
                        {{ ostolaskuNayta.ostolasku.epitieto.bic }}
                      </template>
                    </DefListItem>
                    <DefListItem>
                      <template v-slot:label>
                        Viite
                      </template>
                      <template v-slot:value>
                        {{ ostolaskuNayta.ostolasku.viite }}
                      </template>
                    </DefListItem>
                    <DefListItem v-if="ostolaskuNayta.ostolasku.viitteemme">
                      <template v-slot:label>
                        Viitteemme
                      </template>
                      <template v-slot:value>
                        {{ ostolaskuNayta.ostolasku.viitteemme }}
                      </template>
                    </DefListItem>
                    <DefListItem v-if="ostolaskuNayta.ostolasku.viitteenne">
                      <template v-slot:label>
                        Viitteenne
                      </template>
                      <template v-slot:value>
                        {{ ostolaskuNayta.ostolasku.viitteenne }}
                      </template>
                    </DefListItem>
                  </template>
                  <template v-if="muokkausmoodiPaalla">
                    <DefListItem class="defList__item--edit">
                      <template v-slot:label>
                        IBAN
                      </template>
                      <template v-slot:value>
                        <v-text-field
                          v-model="ostolaskuNayta.muokattavaOstolasku.epitieto.iban"
                          type="text"
                          dense
                          height="24"
                          :rules="[$validationRules.isValidIBAN]"
                        />
                      </template>
                    </DefListItem>
                    <DefListItem class="defList__item--edit">
                      <template v-slot:label>
                        BIC
                      </template>
                      <template v-slot:value>
                        <v-text-field
                          v-model="ostolaskuNayta.muokattavaOstolasku.epitieto.bic"
                          type="text"
                          dense
                          height="24"
                          :rules="[$validationRules.isValidBIC]"
                        />
                      </template>
                    </DefListItem>
                    <DefListItem>
                      <template v-slot:label>
                        Viite
                      </template>
                      <template v-slot:value>
                        {{ ostolaskuNayta.ostolasku.viite }}
                      </template>
                    </DefListItem>
                    <DefListItem
                      class="defList__item--edit"
                      value-class="break-all"
                    >
                      <template v-slot:label>
                        Viitteemme
                      </template>
                      <template v-slot:value>
                        <v-text-field
                          v-model="ostolaskuNayta.muokattavaOstolasku.viitteemme"
                          type="text"
                          dense
                          height="24"
                        />
                      </template>
                    </DefListItem>
                    <DefListItem
                      class="defList__item--edit"
                      value-class="break-all"
                    >
                      <template v-slot:label>
                        Viitteenne
                      </template>
                      <template v-slot:value>
                        <v-text-field
                          v-model="ostolaskuNayta.muokattavaOstolasku.viitteenne"
                          type="text"
                          dense
                          height="24"
                        />
                      </template>
                    </DefListItem>
                  </template>
                  <DefListItem>
                    <template v-slot:label>
                      Summa
                    </template>
                    <template v-slot:value>
                      {{ ostolaskuNayta.ostolasku.summa | formatSumToFixed2 }}
                    </template>
                  </DefListItem>
                </DefList>
              </template>
              <template v-slot:col4>
                <DefList :bordered="(true)">
                  <DefListItem>
                    <template v-slot:label>
                      Veroton
                    </template>
                    <template v-slot:value>
                      {{ ostolaskuNayta.ostolasku.veroton | formatSumToFixed2 }}
                    </template>
                  </DefListItem>
                  <DefListItem>
                    <template v-slot:label>
                      Vero
                    </template>
                    <template v-slot:value>
                      {{ ostolaskuNayta.ostolasku.vero | formatSumToFixed2 }}
                    </template>
                  </DefListItem>
                  <DefListItem v-if="ostolaskuNayta.ostolasku.viivastyskorko">
                    <template v-slot:label>
                      Viivästyskorko
                    </template>
                    <template v-slot:value>
                      {{ ostolaskuNayta.ostolasku.viivastyskorko }} %
                    </template>
                  </DefListItem>
                  <DefListItem>
                    <template v-slot:label>
                      Avoinna
                    </template>
                    <template v-slot:value>
                      <strong>{{ ostolaskuNayta.ostolasku.avoinna | formatSumToFixed2 }}</strong>
                    </template>
                  </DefListItem>
                  <DefListItem>
                    <template v-slot:label>
                      Tiliöinti hyväksytty
                    </template>
                    <template v-slot:value>
                      {{ onkoTilioity }}
                    </template>
                  </DefListItem>
                </DefList>
              </template>
            </TietueenPerustiedotCard>
          </div>
          <div class="pa-4">
            <v-row>
              <!-- Left column -->
              <v-col
                cols="12"
                lg="8"
              >
                <div class="pb-4">
                  <v-card class="pa-6">
                    <CardTitle text="Laskun kuva" />
                    <div v-if="ostolaskuNayta.laskunKuvaData">
                      <div>
                        <object
                          :data="ostolaskuNayta.laskunKuvaData"
                          type="application/pdf"
                          :height="user.tiivisNakyma ? '400px' : '500px'"
                          width="100%"
                        >

                          <iframe
                            :src="ostolaskuNayta.laskunKuvaData"
                            :height="user.tiivisNakyma ? '400px' : '500px'"
                            width="100%"
                          >
                            <p>Selaimesi ei tue PDF:n näyttämistä</p>
                          </iframe>
                        </object>
                      </div>
                    </div>
                    <p v-else>
                      Laskulle ei ole ladattu kuvaa
                    </p>
                  </v-card>
                </div>

                <!-- Laskurivit -->
                <div class="py-4">
                  <v-card class="pa-6">
                    <CardTitle text="Laskurivit" />

                    <v-data-table
                      v-if="!muokkausmoodiPaalla"
                      :items="laskuriviItems"
                      :headers="tableHeadersLaskurivit"
                      :hide-default-footer="laskuriviItems.length <= 10"
                      no-data-text="Ei laskurivejä"
                      :dense="user.tiivisNakyma"
                    >
                      <template v-slot:top>
                        <v-toolbar
                          flat
                          short
                          :dense="user.tiivisNakyma"
                        >
                          <span></span>
                          <v-spacer></v-spacer>
                          <v-btn
                            v-if="!muokkausmoodiPaalla"
                            small
                            color="primary"
                            class="ml-2"
                            :disabled="!muokkausSallittu"
                            @click="asetaMuokkausmoodi"
                          >
                            <v-icon
                              left
                              small
                            >
                              edit
                            </v-icon>
                            Muokkaa laskua
                          </v-btn>
                        </v-toolbar>
                      </template>
                      <template v-slot:item="{ item }">
                        <TableRow>
                          <td class="text-no-wrap">
                            {{ item.koodi }}
                          </td>
                          <td class="max-width">
                            {{ item.selite }}
                          </td>
                          <td class="text-no-wrap text-right">
                            {{ item.alvp }}
                          </td>
                          <td class="text-no-wrap text-right">
                            {{ item.ahinta | formatSumToFixed2 }}
                          </td>
                          <td class="text-no-wrap text-right">
                            {{ $muotoileDesimaaliluku(item.maara) }}
                          </td>
                          <td class="text-no-wrap text-right">
                            {{ item.veroton | formatSumToFixed2 }}
                          </td>
                          <td class="text-no-wrap text-right">
                            {{ item.vero | formatSumToFixed2 }}
                          </td>
                          <td class="text-no-wrap text-right">
                            {{ item.verollinen | formatSumToFixed2 }}
                          </td>
                        </TableRow>
                      </template>
                      <template
                        v-if="laskuriviItems.length"
                        v-slot:body.append
                      >
                        <TableRow>
                          <td
                            colspan="5"
                            class="text-no-wrap text-right"
                          >
                            <strong>Yhteensä</strong>
                          </td>
                          <td class="text-right">
                            <strong>{{ ostolaskuNayta.ostolasku.veroton | formatSumToFixed2 }}</strong>
                          </td>
                          <td class="text-no-wrap text-right">
                            <strong>{{ ostolaskuNayta.ostolasku.vero | formatSumToFixed2 }}</strong>
                          </td>
                          <td class="text-no-wrap text-right">
                            <strong>{{ ostolaskuNayta.ostolasku.summa | formatSumToFixed2 }}</strong>
                          </td>
                        </TableRow>
                      </template>
                    </v-data-table>
                    <template v-if="muokkausmoodiPaalla">
                      <v-toolbar
                        flat
                        short
                        :dense="user.tiivisNakyma"
                      >
                        <span></span>
                        <v-spacer></v-spacer>
                        <v-btn
                          small
                          color="primary"
                          class="ml-2"
                          @click="asetaMuokkausmoodi(false)"
                        >
                          <v-icon
                            left
                            small
                          >
                            cancel
                          </v-icon>
                          Peruuta muutokset
                        </v-btn>
                        <v-btn
                          small
                          color="primary"
                          class="ml-2"
                          @click="tallennaMuutokset"
                        >
                          <v-icon
                            left
                            small
                          >
                            check
                          </v-icon>
                          Tallenna muutokset
                        </v-btn>
                      </v-toolbar>
                      <LaskuriviTable
                        v-model="ostolaskuNayta.muokattavaOstolasku"
                        cell-size="small"
                        tyyppi="ostolasku"
                        class="px-4 py-2"
                        :tuoterekisteri="false"
                        @kayttajasyote="laskurivejaMuokattu = true"
                      />
                    </template>
                  </v-card>
                </div>

                <!-- Tiliöinti -->
                <div class="py-4">
                  <v-card class="pa-6">
                    <CardTitle text="Tiliöinti" />

                    <v-data-table
                      :items="tiliointiItems"
                      :headers="tableHeadersTilioinnit"
                      :hide-default-footer="ostolaskuNayta.ostolasku.ostolaskurivi_set.length <= 10"
                      no-data-text="Ei rivejä"
                      :dense="user.tiivisNakyma"
                    >
                      <template v-slot:top>
                        <v-toolbar
                          flat
                          short
                          :dense="user.tiivisNakyma"
                        >
                          <DefListItem>
                            <template v-slot:label>
                              Tiliöinti hyväksytty
                            </template>
                            <template v-slot:value>
                              {{ onkoTilioity }}
                            </template>
                          </DefListItem>
                          <span></span>
                          <v-spacer></v-spacer>
                          <v-btn
                            small
                            color="primary"
                            class="ml-2"
                            :disabled="ostolaskuNayta.ostolasku.tilioity"
                            @click="tiliointiPalauta"
                          >
                            <v-icon
                              left
                              small
                            >
                              keyboard_return
                            </v-icon>
                            Palauta esitiliöinti
                          </v-btn>
                          <v-btn
                            v-if="!ostolaskuNayta.ostolasku.tilioity"
                            small
                            color="primary"
                            class="ml-2"
                            @click="tiliointiHyvaksy"
                          >
                            <v-icon
                              left
                              small
                            >
                              check
                            </v-icon>
                            Hyväksy tiliöinti
                          </v-btn>
                          <v-btn
                            v-else-if="ostolaskuNayta.ostolasku.tilioity"
                            small
                            color="primary"
                            class="ml-2"
                            @click="tiliointiPeru"
                          >
                            <v-icon
                              left
                              small
                            >
                              cancel
                            </v-icon>
                            Peru tiliöinti
                          </v-btn>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.tili="props">
                        <v-edit-dialog
                          :ref="`editDialog-${props.item.id}`"
                          :return-value.sync="props.item.tili"
                          @save="tallennaTiliointirivi(props.item)"
                        >
                          {{ muotoileTiliointitili(props.item.tiliointitili_id) }}
                          <template v-slot:input>
                            <v-autocomplete
                              v-model="props.item.tiliointitili_id"
                              :disabled="ostolaskuNayta.ostolasku.tilioity"
                              :items="tiliointitiliItems"
                              item-text="tunnus"
                              item-value="id"
                              @input="suljeEditDialogJaTallennaRivi(props.item)"
                            ></v-autocomplete>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-slot:item.summa="props">
                        <v-edit-dialog
                          :return-value.sync="props.item.summa"
                          @save="$refs[`textFieldSumma-${props.item.id}`].valid ? tallennaTiliointirivi(props.item) : () => {}"
                          @open="tallennaTiliointirivinAlkupArvo(props.item.summa)"
                          @cancel="props.item.summa = palautaTiliointirivinAlkupArvo()"
                          @close="$refs[`textFieldSumma-${props.item.id}`].valid ? () => {} : $nextTick(() => { props.item.summa = palautaTiliointirivinAlkupArvo() })"
                        >
                          <template v-slot:default>
                            {{ props.item.summa | formatSumToFixed2 }}
                          </template>
                          <template v-slot:input>
                            <v-text-field
                              :ref="`textFieldSumma-${props.item.id}`"
                              v-model.number="props.item.summa"
                              :disabled="ostolaskuNayta.ostolasku.tilioity"
                              type="number"
                              :rules="[
                                $validationRules.maxDecimalPlaces(props.item.summa, 2),
                                $validationRules.requiredLoose,
                              ]"
                              single-line
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-slot:item.alvp="props">
                        <v-edit-dialog
                          :return-value.sync="props.item.alvp"
                          @save="$refs[`textFieldAlvp-${props.item.id}`].valid ? tallennaTiliointirivi(props.item) : () => {}"
                          @open="tallennaTiliointirivinAlkupArvo(props.item.alvp)"
                          @cancel="props.item.alvp = palautaTiliointirivinAlkupArvo()"
                          @close="$refs[`textFieldAlvp-${props.item.id}`].valid ? () => {} : $nextTick(() => { props.item.alvp = palautaTiliointirivinAlkupArvo() })"
                        >
                          <template v-slot:default>
                            {{ $muotoileDesimaaliluku(props.item.alvp) }}
                          </template>
                          <template v-slot:input>
                            <v-text-field
                              :ref="`textFieldAlvp-${props.item.id}`"
                              v-model.number="props.item.alvp"
                              :disabled="ostolaskuNayta.ostolasku.tilioity"
                              type="number"
                              :rules="[
                                $validationRules.maxDecimalPlaces(props.item.alvp, 2),
                                $validationRules.requiredLoose,
                              ]"
                              single-line
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-slot:item.selite="props">
                        <v-edit-dialog
                          :return-value.sync="props.item.selite"
                          @save="tallennaTiliointirivi(props.item)"
                        >
                          {{ props.item.selite }}
                          <template v-slot:input>
                            <v-text-field
                              v-model="props.item.selite"
                              :disabled="ostolaskuNayta.ostolasku.tilioity"
                              single-line
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                    </v-data-table>
                  </v-card>
                </div>

                <!-- Kirjaukset -->
                <!-- <div class="py-4">
                  <v-card class="pa-6">
                    <CardTitle text="Kirjaukset" />

                    <v-data-table
                      :items="kirjauksetItems"
                      :headers="tableHeadersLaskuKirjaukset"
                      :hide-default-footer="kirjauksetItems.length <= 10"
                      no-data-text="Ei kirjauksia"
                      :dense="user.tiivisNakyma"
                    >
                      <template v-slot:item="{ item }">
                        <TableRow>
                          <td class="max-width">
                            <strong>{{ item.maksaja }}</strong>
                          </td>
                          <td class="text-no-wrap text-right">
                            {{ $date(item.maksupaiva) }}
                          </td>
                          <td class="text-no-wrap text-right">
                            {{ $date(item.kirjauspaiva) }}
                          </td>
                          <td class="text-no-wrap text-right">
                          </td>
                        </TableRow>
                        <TableRow
                          v-for="subRow in item.subRows"
                          :key="subRow.id"
                          :subRow="(true)"
                        >
                          <td class="max-width">
                            {{ subRow.viesti }}
                              <span v-if="subRow.vastakirjauslasku">
                                (ostolasku
                                <router-link
                                  :to="{ name: 'laskutus-nayta', params: { id: subRow.vastakirjauslasku.id } }"
                                >
                                  {{ subRow.vastakirjauslasku.nro }})
                                </router-link>
                              </span>
                          </td>
                          <td class="text-no-wrap text-right">
                          </td>
                          <td class="text-no-wrap text-right">
                            {{ $date(subRow.kirjauspaiva) }}
                          </td>
                          <td class="text-no-wrap text-right">
                            {{ subRow.summa | formatSumToFixed2 }}
                          </td>
                        </TableRow>
                      </template>
                      <template
                        v-if="kirjauksetItems.length"
                        v-slot:body.append
                      >
                        <TableRow>
                          <td colspan="3" class="text-no-wrap text-right">
                            <strong>Yhteensä</strong>
                          </td>
                          <td class="text-no-wrap text-right">
                            <strong>{{ ostolasku.kirjattu | formatSumToFixed2 }}</strong>
                          </td>
                        </TableRow>
                      </template>
                    </v-data-table>
                  </v-card>
                </div> -->

                <!-- Liitteet ja tiedostot -->
                <div
                  v-if="laskuliitteetLkm"
                  class="py-4"
                >
                  <v-card class="pa-6">
                    <CardTitle text="Liitteet ja tiedostot" />

                    <DataTableLaskuliite
                      :items="ostolaskuNayta.ostolasku.laskuliite_set"
                      :lasku="ostolaskuNayta.ostolasku"
                      tyyppi="ostolasku"
                      @tiedosto-poistettu="loadItem(true)"
                    />
                  </v-card>
                </div>

                <!-- Lisätiedot -->
                <div
                  v-if="lisatiedotItems.length || ostolaskuNayta.ostolasku.toimitusosoite"
                  class="py-4"
                >
                  <v-card class="pa-6">
                    <CardTitle text="Lisätiedot" />

                    <template v-if="lisatiedotItems.length">
                      <v-data-table
                        :items="lisatiedotItems"
                        :headers="tableHeadersLisatiedot"
                        :hide-default-footer="(true)"
                        :items-per-page="999"
                        no-data-text="Ei lisätietoja"
                        dense
                      >
                        <template v-slot:item="{ item }">
                          <TableRow>
                            <td class="text-no-wrap">
                              <strong>{{ item.key }}</strong>
                            </td>
                            <td class="max-width">
                              {{ item.value }}
                            </td>
                          </TableRow>
                        </template>
                      </v-data-table>
                    </template>

                    <template v-if="ostolaskuNayta.ostolasku.toimitusosoite">
                      <h3 class="pt-4 pb-2">
                        Toimitusosoite
                      </h3>
                      <OsoiteKappale
                        v-if="ostolaskuNayta.ostolasku.toimitusosoite"
                        :saaja="ostolaskuNayta.ostolasku.toimitusosoite.saaja ? ostolaskuNayta.ostolasku.toimitusosoite.saaja : ''"
                        :postiosoite="ostolaskuNayta.ostolasku.toimitusosoite.osoite"
                        :postinumero="ostolaskuNayta.ostolasku.toimitusosoite.numero"
                        :postitoimipaikka="ostolaskuNayta.ostolasku.toimitusosoite.toimipaikka"
                        class="mb-2"
                      />
                      <span v-if="ostolaskuNayta.ostolasku.toimitustapa">Toimitustapa: {{ ostolaskuNayta.ostolasku.toimitustapa }}</span>
                    </template>
                  </v-card>
                </div>
              </v-col>

              <!-- Right column -->
              <v-col
                cols="12"
                lg="4"
              >
                <!-- Timeline -->
                <v-card class="pa-6">
                  <Timeline
                    :items="ostolaskuNayta.ostolasku.aikajana"
                    :enable-reply="(true)"
                    @msg-mark-as-unread="loadItem(true)"
                    @msg-mark-as-read="loadItem(true)"
                    @reply="avaaDialogViestit"
                    @muistiinpano-muokkaa-submit="loadItem(true)"
                    @muistiinpano-poistettu="loadItem(true)"
                  />
                </v-card>
              </v-col>
            </v-row>
          </div>

          <!-- Muokkausmoodin painikkeet -->
          <v-card
            v-show="muokkausmoodiPaalla"
            class="fixed-card py-4"
            flat
          >
            <v-btn
              large
              class="mx-4 elevation-8"
              @click="asetaMuokkausmoodi(false)"
            >
              Peruuta muutokset
            </v-btn>
            <v-btn
              large
              color="success"
              class="mx-2 elevation-8"
              @click="tallennaMuutokset"
            >
              Tallenna muutokset
            </v-btn>
          </v-card>
        </v-container>
      </v-form>
    </template>

    <!-- Dialog: Viestit -->
    <CardDialog
      :title="$t('common:sendMessageHeader', { asiaa: 'ostolaskunumeroa ' + ostolaskuNayta.ostolasku.nro })"
      :dialog-auki="uusiViestiDialogAuki"
      :scroll-to-bottom="(true)"
      content-class="px-4 py-2"
      @close="(uusiViestiDialogAuki = false)"
    >
      <template v-slot:default>
        <Timeline
          v-if="viestit.length"
          class="px-4"
          :items="viestit"
          :show-title="(false)"
          :show-item-titles="(false)"
          :show-dates="(true)"
          :enable-zoom="(false)"
          :enable-direction-toggle="(false)"
          @msg-mark-as-unread="loadItem(true)"
          @msg-mark-as-read="loadItem(true)"
        />
      </template>
      <template v-slot:footer>
        <FormUusiViesti
          v-model="ostolaskuNayta.uusiViestiFormData"
          :loading="uusiViestiFormLoading"
          :focus-on-update="(true)"
          painikkeet
          @submit="viestiSubmit"
          @close="(uusiViestiDialogAuki = false)"
        />
      </template>
    </CardDialog>

    <!-- Dialog: Muistiinpanot -->
    <CardDialog
      title="Muistiinpanot"
      :dialog-auki="uusiMuistiinpanoDialogAuki"
      :scroll-to-bottom="(true)"
      content-class="px-4 py-2"
      @close="(uusiMuistiinpanoDialogAuki = false)"
    >
      <template v-slot:default>
        <Timeline
          v-if="muistiinpanot.length"
          class="px-4"
          :items="muistiinpanot"
          :show-title="(false)"
          :show-item-titles="(false)"
          :show-dates="(true)"
          :enable-zoom="(false)"
          :enable-direction-toggle="(false)"
          @muistiinpano-muokkaa-submit="loadItem(true)"
          @muistiinpano-poistettu="loadItem(true)"
        />
      </template>
      <template v-slot:footer>
        <FormUusiViesti
          v-model="ostolaskuNayta.uusiMuistiinpanoFormData"
          :loading="uusiMuistiinpanoFormLoading"
          :focus-on-update="(true)"
          icon="note"
          msg-input-placeholder="Uusi muistiinpano"
          submit-btn-tooltip-text="Kirjoita muistiinpano"
          painikkeet
          @submit="muistiinpanoSubmit"
          @close="(uusiMuistiinpanoDialogAuki = false)"
        />
      </template>
    </CardDialog>

    <!-- Dialog: Lisää liitteitä -->
    <CardDialog
      :title="$t('common:attachmentAdd')"
      :dialog-auki="liitteetDialogAuki"
      @close="(liitteetDialogAuki = false)"
    >
      <FormLiitteet
        v-model="ostolaskuNayta.liitteetFormData"
        class="pa-4"
        :loading="liitteetFormLoading"
        @cancel="liitteetDialogAuki = false"
        @submit="liitteetSubmit"
        @close="(liitteetDialogAuki = false)"
      />
    </CardDialog>

    <!-- Dialog: Vie maksuun -->
    <CardDialog
      title="Vie lasku maksuun"
      :dialog-auki="vieMaksuunDialogAuki"
      @close="(vieMaksuunDialogAuki = false)"
    >
      <FormVieMaksuun
        v-model="ostolaskuNayta.vieMaksuunFormData"
        class="pa-4"
        :on-auki="vieMaksuunDialogAuki"
        :loading="vieMaksuunFormLoading"
        :ostolasku="ostolaskuNayta.ostolasku"
        :paamies="ostolaskuNayta.ostolasku.paamies"
        :ostotili-items="ostolaskuNayta.ostotiliItems"
        @cancel="vieMaksuunDialogAuki = false"
        @close="(vieMaksuunDialogAuki = false)"
        @submit="vieMaksuun"
      />
    </CardDialog>

    <!-- Dialog: Hintoja muokattu -->
    <CardDialog
      title="Anna selite"
      :dialog-auki="laskurivejaMuokattuDialogAuki"
      @close="(laskurivejaMuokattuDialogAuki = false)"
    >
      <FormSeliteMuokkaukselle
        v-model="ostolaskuNayta.laskurivejaMuokattuFormData"
        class="pa-4"
        :on-auki="laskurivejaMuokattuDialogAuki"
        :loading="laskurivejaMuokattuFormLoading"
        @cancel="laskurivejaMuokattuDialogAuki = false"
        @peruuta-muutokset="peruutaMuutoksetJaSuljeLaskurivejaMuokattuDialog"
        @submit="kasitteleLaskurivejaMuokattuSubmit"
      >
        <v-card class="mx-2 pa-4 mb-8 warning">
          Ostolaskun laskurivejä on muokattu. Anna selite laskurivien muokkaukselle.
        </v-card>
      </FormSeliteMuokkaukselle>
    </CardDialog>
  </div>
</template>

<script>

import _ from 'lodash'
import { mapState, mapGetters } from 'vuex'
import Laskurivi from '@/class/Laskurivi'
import { Ostolasku } from '@/class/Lasku'
import {
  OsoiteKappale,
  FormLiitteet,
  FormSeliteMuokkaukselle,
  FormUusiViesti,
  FormVieMaksuun,
  LaskuriviTable,
  StatusText,
  TietueenPerustiedotCard,
  Timeline,
} from '@/components'
import {
  TableHeadersLaskurivit,
  TableHeadersLaskuKirjaukset,
  TableHeadersLisatiedot,
  TableHeadersLaskuliite,
  TableHeadersTilioinnit,
} from '@/utils/tableHeaders'

export default {
  name: 'OstolaskuNayta',
  components: {
    OsoiteKappale,
    FormLiitteet,
    FormSeliteMuokkaukselle,
    FormUusiViesti,
    FormVieMaksuun,
    LaskuriviTable,
    StatusText,
    TietueenPerustiedotCard,
    Timeline,
  },
  data () {
    return {
      erapaivaMenu: false,
      laskurivejaMuokattu: false,
      laskurivejaMuokattuDialogAuki: false,
      laskurivejaMuokattuFormLoading: false,
      laskupaivaMenu: false,
      liitteetDialogAuki: false,
      liitteetFormLoading: false,
      tietueLataamassa: false,
      tietueLatausEpaonnistunut: false,
      muokkausmoodiPaalla: false,
      tableHeadersLaskuKirjaukset: TableHeadersLaskuKirjaukset || [],
      tableHeadersLaskuliite: TableHeadersLaskuliite || [],
      tableHeadersLaskurivit: TableHeadersLaskurivit || [],
      tableHeadersLisatiedot: TableHeadersLisatiedot || [],
      tableHeadersTilioinnit: TableHeadersTilioinnit || [],
      uusiMuistiinpanoDialogAuki: false,
      uusiMuistiinpanoFormLoading: false,
      uusiViestiDialogAuki: false,
      uusiViestiFormLoading: false,
      vieMaksuunDialogAuki: false,
      vieMaksuunFormLoading: false,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      ostolaskuNayta: state => state.ostolaskuNayta,
    }),
    ...mapGetters({
      tiliointitiliItems: 'yleinen/tiliointitiliItems',
      haeTiliointitili: 'yleinen/haeTiliointitili',
    }),

    tunnustyyppi () {
      if (!this.ostolaskuNayta.ostolasku.toimittaja.tyyppi) return null

      if (this.ostolaskuNayta.ostolasku.toimittaja.tyyppi == 'H') {
        return 'Hetu'
      } else if (this.ostolaskuNayta.ostolasku.toimittaja.tyyppi == 'Y') {
        return 'Y-tunnus'
      } else {
        return 'Tunnus'
      }
    },

    hyvaksyMaksuunSallittu () {
      return this.ostolaskuNayta.ostolasku.tila.value === Ostolasku.TILA_AVOINNA
    },

    hylkaaLaskuSallittu () {
      return [Ostolasku.TILA_AVOINNA, Ostolasku.TILA_HYVAKSYTTY_MAKSUUN].includes(this.ostolaskuNayta.ostolasku.tila.value)
    },

    muokkausSallittu () {
      // check perm
      return this.ostolaskuNayta.ostolasku.tila.value === Ostolasku.TILA_AVOINNA
    },

    palautaAvoimeksiSallittu () {
      return [Ostolasku.TILA_PERUTTU, Ostolasku.TILA_HYVAKSYTTY_MAKSUUN].includes(this.ostolaskuNayta.ostolasku.tila.value)
    },

    vieMaksuunSallittu () {
      return this.ostolaskuNayta.ostolasku.tila.value === Ostolasku.TILA_HYVAKSYTTY_MAKSUUN
    },

    muistiinpanot () {
      return this.ostolaskuNayta.ostolasku.aikajana.filter(item => item.tyyppi == 'Muistiinpano')
    },

    viestit () {
      return this.ostolaskuNayta.ostolasku.aikajana.filter(item => item.tyyppi == 'PaamiesViesti')
    },

    laskuriviItems () {
      return this.ostolaskuNayta.ostolasku.laskusisalto_set.map(item => {
        return {
          koodi: item.koodi,
          selite: item.selite,
          alvp: item.alvp,
          alvtunnus_id: item.alvtunnus_id,
          ahinta: item.ahinta,
          maara: item.maara,
          veroton: item.veroton,
          vero: item.vero,
          verollinen: item.verollinen,
        }
      })
    },

    tiliointiItems () {
      if (!this.ostolaskuNayta.ostolasku.ostolaskurivi_set.length) return []

      return this.ostolaskuNayta.ostolasku.ostolaskurivi_set[0].tiliointi_set.map(item => {
        item.alvp = this.$muotoileDesimaaliluku(item.alvp)
        return item
      }
      )
    },

    kirjauksetItems () {
      // return this.ostolasku.tapahtuma_set_laskukirjauksilla.map(tapahtuma => {
      //   return {
      //     maksaja: tapahtuma.maksaja,
      //     maksupaiva: tapahtuma.maksupaiva,
      //     kirjauspaiva: tapahtuma.kirjauspaiva,
      //     subRows: tapahtuma.kirjaus_set.map(kirjaus => {
      //       return {
      //         id: kirjaus.id,
      //         viesti: kirjaus.viesti,
      //         kirjauspaiva: kirjaus.kirjauspaiva,
      //         summa: kirjaus.summa,
      //         vastakirjauslasku: kirjaus.vastakirjauslasku
      //       }
      //     })
      //   }
      // })
      return []
    },

    lisatiedotItems () {
      if (!this.ostolaskuNayta.ostolasku.laskulisatieto_set) return []

      let items = this.ostolaskuNayta.ostolasku.laskulisatieto_set.map(item => {
        return {
          id: item.id,
          key: item.key,
          value: item.value,
        }
      })

      if (this.ostolaskuNayta.ostolasku.vapaateksti) items.push({
        id: 'vapaateksti',
        key: 'Vapaateksti',
        value: this.ostolaskuNayta.ostolasku.vapaateksti,
      })

      return items
    },

    laskuliitteetLkm () {
      return this.ostolaskuNayta.ostolasku.laskuliite_set.length
    },

    routeTitle () {
      if (this.ostolaskuNayta.ostolasku.nro) {
        return `${this.$route.meta.title} ${this.ostolaskuNayta.ostolasku.nro}`
      } else {
        return this.$route.meta.title
      }
    },
    onkoTilioity () {
      return this.ostolaskuNayta.ostolasku.tilioity === true ? 'Kyllä' : 'Ei'
    }
  },
  watch: {
    '$route.params.id': {
      handler () {
        this.loadItem()
      }
    },
    muokkausmoodiPaalla: {
      handler(val) {
        if (val) this.ostolaskuNayta.muokattavaOstolasku = Object.assign(
          new Ostolasku(),
          this.ostolaskuNayta.ostolasku,
          { laskunumero: this.ostolaskuNayta.ostolasku.nro }
        )

        for (const rivi of this.laskuriviItems) {
          this.ostolaskuNayta.muokattavaOstolasku.rivit.push(new Laskurivi(rivi))
        }
      }
    }
  },
  async created () {
    await this.loadItem()
    if (!this.tietueLatausEpaonnistunut) {
      await this.suodataLaskunPaamiehenTilit()
    }
  },
  methods: {
    async loadItem (silent = false) {
      try {
        if (!silent) this.tietueLataamassa = true
        await this.$store.dispatch('ostolaskuNayta/loadItem', this.$route)
      } catch (e) {
        if (e.request && e.request.result && e.request.result.status === 404) {
          this.$naytaVirheilmoitus('Laskua ei löytynyt!')

          this.$router.replace({ name: 'error404' })
        } else {
          this.tietueLatausEpaonnistunut = true
          this.$oletusVirheenkasittely(e, 'Tietojen lataaminen ei onnistunut! Yritä hetken kuluttua uudelleen.')
        }
      } finally {
        this.tietueLataamassa = false
      }
    },
    async suodataLaskunPaamiehenTilit () {
      try {
        await this.$store.dispatch('ostolaskuNayta/suodataLaskunPaamiehenTilit')
      } catch (e) {
        this.$oletusVirheenkasittely(e, 'Tietojen lataaminen ei onnistunut! Yritä hetken kuluttua uudelleen.')
      }
    },

    avaaDialogLiitteet () {
      this.$store.commit('ostolaskuNayta/setLiitteetFormData', {})
      this.liitteetDialogAuki = true
    },

    async liitteetSubmit () {
      try {
        this.liitteetFormLoading = true
        const onnistumisTeksti = await this.$store.dispatch('ostolaskuNayta/liitteetSubmit')
        this.$naytaOnnistumisilmoitus(onnistumisTeksti)
        this.liitteetDialogAuki = false
      } catch (e) {
        if (!this.$onkoUiVirhe(e)) {
          this.$sentryCaptureCustom(e)

          let virheviesti = ''

          if (e.name === this.$SallittuKokoYlitettyError.name) {
            virheviesti = this.$t('common:attachmentSizeExceeded', { filename: e.extra })
          } else {
            virheviesti = this.$t('common:attachmentSendingFailed')
          }

          this.$naytaVirheilmoitus(virheviesti)
        }
      } finally {
        this.liitteetFormLoading = false
      }
    },
    paivitaLasku (paivitettyLasku) {
      const ostolasku = Object.assign({}, paivitettyLasku, { laskunumero: paivitettyLasku.nro })
      this.$store.commit('ostolaskuNayta/setOstolasku', ostolasku)
    },
    avaaDialogViestit () {
      this.uusiViestiDialogAuki = true
      this.$store.commit('ostolaskuNayta/setUusiViestiFormData', {})
    },

    avaaDialogVieMaksuun () {
      this.$store.commit('ostolaskuNayta/setVieMaksuunFormData', {})
      this.vieMaksuunDialogAuki = true
    },

    async viestiSubmit () {
      try {
        this.uusiViestiFormLoading = true
        await this.$store.dispatch('ostolaskuNayta/viestiSubmit')
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.uusiViestiFormLoading = false
      }
    },

    avaaDialogMuistiinpanot () {
      this.$store.commit('ostolaskuNayta/setUusiMuistiinpanoFormData', {})
      this.uusiMuistiinpanoDialogAuki = true
    },

    async muistiinpanoSubmit () {
      try {
        this.uusiMuistiinpanoFormLoading = true
        await this.$store.dispatch('ostolaskuNayta/muistiinpanoSubmit')
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.uusiMuistiinpanoFormLoading = false
      }
    },
    hyvaksyMaksuun () {
      this.teeOstolaskuToiminto('hyvaksy_maksuun')
    },

    hylkaaLasku () {
      this.teeOstolaskuToiminto('hylkaa')
    },

    palautaAvoimeksi () {
      this.teeOstolaskuToiminto('avoimeksi')
    },

    async vieMaksuun () {
      this.vieMaksuunFormLoading = true
      try {
        await this.teeOstolaskuToiminto('maksa', this.ostolaskuNayta.vieMaksuunFormData)
        await this.tilitaOstolaskut()
        this.$naytaOnnistumisilmoitus('Lasku viety maksuun.')
        this.vieMaksuunDialogAuki = false
      } finally {
        this.vieMaksuunFormLoading = false
      }
    },

    async teeOstolaskuToiminto (endpoint, body = {}) {
      try {
        await this.$store.dispatch('ostolaskuNayta/teeOstolaskuToiminto', { endpoint, body })
      } catch (e) {
        console.log(e)
        this.$oletusVirheenkasittely(e)
      }
    },

    async tilitaOstolaskut () {

      try {
        await this.$store.dispatch('ostolaskuNayta/tilitaOstolaskut')
      } catch (e) {
        console.log(e)
        this.$oletusVirheenkasittely(e, 'Tilitys epäonnistui')
      }
    },

    asetaMuokkausmoodi (paalle = true) {
      if (paalle) {
        this.muokkausmoodiPaalla = true
      } else
        this.muokkausmoodiPaalla = false
        this.laskurivejaMuokattu = false
    },

    async tallennaMuutokset () {
      if (!this.muokkausSallittu && this.muokkausmoodiPaalla) {
        this.$naytaVirheilmoitus('Lasku ei ole muokattavissa! Vain avoimia ostolaskuja voi muokata.')
        return
      }

      if (!this.validoiLasku()) return

      if (this.laskurivejaMuokattu) {
        this.laskurivejaMuokattuDialogAuki = true
      } else {
        const resultBody = await this.lahetaMuutokset()
        this.paivitaLasku(resultBody)
      }
    },
    validoiLasku () {
      let lomakeValidi = true

      if (!this.$refs.formMuokkaus.validate()) {
        this.$naytaVirheilmoitus('Tarkista kenttien sisältö!')
        lomakeValidi = false
      }

      return lomakeValidi
    },
    async lahetaMuutokset (rethrowError = false) {
      this.tietueLataamassa = true

      try {
        const responseBody = await this.$store.dispatch('ostolaskuNayta/lahetaMuutokset')
        this.muokkausmoodiPaalla = false
        this.laskurivejaMuokattu = false
        this.$naytaOnnistumisilmoitus('Laskun muokkaus tallennettu!')
        return responseBody
      } catch (e) {
        this.$oletusVirheenkasittely(e, 'Laskun muokkauksen tallennus epäonnistui!')
        if (rethrowError) throw e
      } finally {
        this.tietueLataamassa = false
      }
    },
    peruutaMuutoksetJaSuljeLaskurivejaMuokattuDialog () {
      this.asetaMuokkausmoodi(false)
      this.laskurivejaMuokattuDialogAuki = false
      this.$store.commit('ostolaskuNayta/setLaskurivejaMuokattuFormData', {})
    },
    async kasitteleLaskurivejaMuokattuSubmit () {
      try {
        this.laskurivejaMuokattuFormLoading = true
        try {
          await this.lahetaMuutokset(true)
        } catch {
          // Ei lähetetä muistiinpanoa jos muokkauksen tallennus epäonnistuu
          return
        }
        await this.$store.dispatch('ostolaskuNayta/kasitteleLaskurivejaMuokattuSubmit')

        this.laskurivejaMuokattuDialogAuki = false
        this.laskurivejaMuokattu = false
      } finally {
        this.laskurivejaMuokattuFormLoading = false
      }
    },
    tiliointiHyvaksy () {
      this.teeOstolaskuToiminto('hyvaksy_tiliointi')
    },
    tiliointiPeru () {
      this.teeOstolaskuToiminto('peru_tiliointi')
    },
    tiliointiPalauta () {
      this.teeOstolaskuToiminto('palauta_esitiliointi')
    },
    muotoileTiliointitili (tiliointitili_id) {
      return this.haeTiliointitili(tiliointitili_id).tunnus
    },
    async tallennaTiliointirivi (rivi, uusi=false) {
      try {
        if (this.ostolaskuNayta.ostolasku.tilioity) {
          this.$naytaVaroitusilmoitus('Tiliöintirivejä ei voi muokata kun tiliöinti on hyväksytty. Muokataksesi rivejä peru ensin tiliöinti.')
          return
        }
        await this.$store.dispatch('ostolaskuNayta/tallennaTiliointirivi', { rivi, uusi })
      } catch (e) {
        console.log(e)
        this.$oletusVirheenkasittely(e)
        this.loadItem(true) // Päivitetään lasku palvelimelta, jottei näytetä tiliöintitaulukossa väärää tietoa
      }
    },
    suljeEditDialogJaTallennaRivi (item) {
      const ref = `editDialog-${item.id}`
      this.$refs[ref].save() // Sulkee edit-dialogin
      this.tallennaTiliointirivi(item)
    },
    tallennaTiliointirivinAlkupArvo (arvo) {
      this.ostolaskuNayta.kasiteltavanTiliointirivinAlkupArvo = arvo
    },
    palautaTiliointirivinAlkupArvo () {
      return this.ostolaskuNayta.kasiteltavanTiliointirivinAlkupArvo
    },
  },
}

</script>

<style lang="scss" scoped>

  .columnSet {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    &--toimeksianto {
      flex-direction: row;

      > .column {
        &:first-child {
          flex: 0 0 70%;
          background: red;
        }

        &:last-child {
          flex: 1 1 auto;
          background: blue;
        }
      }
    }
  }

  .fixed-card {
    z-index: 10;
    position: fixed;
    bottom: 12px;
    right: 12px;
    background-color: rgba(255, 255, 255, 0)
  }

</style>
