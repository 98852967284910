<template>
  <div class="ostolasku-maksutoimeksianto-selaa">
    <!-- Top bar -->
    <ViewSelaaTopBarBase
      pikahaun-placeholder-teksti="Hae (tiedoston nimi, ID)"
      :vuex-module-name="vuexModuleName"
      :search-panel="(false)"
      @open-search-panel="openSearchPanel"
      @close-search-panel="closeSearchPanel"
    />

    <!-- Toolbar -->
    <ViewSelaaToolbar
      :vuex-module-name="vuexModuleName"
      item-partitiivi="maksutoimeksiantoa"
      :alt-selaa-btn-config="{ toName: 'ostolasku-selaa', itemPartitiivi: 'ostolaskuja' }"
    />

    <!-- Show items as table -->
    <v-data-table
      class="v-data-table--striped"
      :headers="tableHeaders"
      :items="items"
      :hide-default-footer="items.length < 10"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50, 100],
      }"
      :multi-sort="true"
      :server-items-length="itemsTotal"
      :options.sync="pagination"
      :loading="loading"
      loading-text=""
      no-data-text=""
      :dense="tiivisNakyma"
    >
      <template v-slot:progress>
        <v-progress-linear
          color="primary"
          indeterminate
        />
      </template>
      <template v-slot:item="{ item }">
        <TableRow
          :clickable="(true)"
          :data="item"
          :fade="loading"
          @click="lataaMaksutoimeksiantotiedosto(item)"
        >
          <td class="text-no-wrap">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <StatusIcon
                  icon="invoice-draft"
                  :size="tiivisNakyma ? 'small' : 'medium'"
                  :hover-icon="('file-download')"
                  bg-color="primary"
                  :event-listener="on"
                  @click="lataaMaksutoimeksiantotiedosto(item)"
                />
              </template>
              <template v-slot:default>
                <span>Lataa tiedosto</span>
              </template>
            </v-tooltip>
          </td>
          <td class="max-width">
            {{ kasitteleTiedostonNimi(item.tiedosto) }}
          </td>
          <td class="text-no-wrap">
            {{ $date(item.luotu) }}
          </td>
          <td class="text-no-wrap">
            {{ item.id }}
          </td>
          <td class="text-no-wrap">
            <span v-if="item.lahetetty">Lähetetty {{ $date(item.lahetetty) }}</span>
            <v-btn
              v-else
              small
              color="primary"
              @click.stop="lahetaTiedostoNetboxiin(item.id)"
            >
              Lähetä
              <v-icon
                right
                x-small
              >
                send
              </v-icon>
            </v-btn>
          </td>
        </TableRow>
      </template>
    </v-data-table>

    <!-- Pagination -->
    <v-toolbar
      v-if="(pagesTotal > 1)"
      class="my-6 py-2 transparent"
      flat
    >
      <v-spacer />
      <Pagination
        v-model="pagination.page"
        :length="pagesTotal"
        :disabled="loading"
      />
      <v-spacer />
    </v-toolbar>
  </div>
</template>

<script>

import {
  Pagination,
  ViewSelaaToolbar,
  ViewSelaaTopBarBase
} from '@/components'
import ViewSelaaMixin from '@/mixins/ViewSelaaMixin'
import { TableHeadersMaksutoimeksiannot } from '@/utils/tableHeaders'

export default {
  name: 'TuoterekisteriTuoteryhmaSelaa',
  components: {
    Pagination,
    ViewSelaaToolbar,
    ViewSelaaTopBarBase,
  },
  mixins: [ViewSelaaMixin],
  data () {
    return {
      tableHeaders: TableHeadersMaksutoimeksiannot,
    }
  },
  methods: {
    async lataaMaksutoimeksiantotiedosto (item) {
      const request = await this.$doRequestWithTimeout(
        this.$api.OstolaskuMaksutoimeksiantoTiedosto,
        {
          url: '',
          params: { id: item.id },
        },
        'doSingleRequest',
      )

      const tiedostonNimi = this.kasitteleTiedostonNimi(item.tiedosto)

      this.$naytaTiedostonlataamisdialog(({
        fileData: request.result.body,
        filename: tiedostonNimi,
        headers: request.result.headers,
      }))
    },
    kasitteleTiedostonNimi (polku) {
      let polkuArr = polku.split('/')
      return polkuArr[polkuArr.length - 1]
    },
    async lahetaTiedostoNetboxiin (id) {
      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.OstolaskuMaksutoimeksiannot,
          {
            url: `${id}/laheta_tiedosto/`,
            method: 'post',
          },
          'doSingleRequest',
        )

        if (!request.success) throw new this.$HttpError(request)

        this.$naytaOnnistumisilmoitus('Tiedosto viety Netboxiin.')

        this.loadItems()
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      }
    },
  },
}

</script>

<style lang="scss" scoped>

</style>
